import React from "react";
import { observer } from "mobx-react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useAppStore } from "@/stores";
import { IPaymentData } from "@/types/IOnRamp";
import Select from "@/components/common/select/Select";
import SelectOption from "@/components/common/select/SelectOption";
import SelectOptions from "@/components/common/select/SelectOptions";

interface SelectPaymentsProps {}

const SelectPayments: React.FC<SelectPaymentsProps> = observer(() => {
  const {
    onRampStore: { payments, paymentSelected, setPayment },
  } = useAppStore();

  const onSelect = (item: IPaymentData) => {
    setPayment(item);
  };

  return (
    <>
      <Select value={<SelectOption item={paymentSelected} title={<TitleComponent item={paymentSelected} />} sx={optionSx} />}>
        <SelectOptions
          items={payments}
          handleSelect={onSelect}
          headerTitle={"Select payment service"}
          optionComponent={(item) => <SelectOption item={item} title={<TitleComponent item={item} />} sx={optionSx} />}
        />
      </Select>
    </>
  );
});

const TitleComponent = ({ item }: { item?: IPaymentData }) => {
  return (
    <Grid container textAlign={"left"}>
      <Grid xs={12} item alignSelf={"center"} pl={{ xs: 1, sm: 2 }}>
        <Typography fontSize={{ xs: "0.75rem", sm: "0.8rem" }}>{item?.name}</Typography>
      </Grid>
      <Grid xs={12} item alignSelf={"center"} pl={{ xs: 1, sm: 2 }}>
        <Typography fontSize={{ xs: "0.625rem", sm: "0.7rem" }} fontWeight="400">
          {item?.feeType === "fixed" ? (
            <>
              Gateway Fee: {item?.fee ?? 0} {item?.fiatCode}
            </>
          ) : (
            <>Gateway Fee: {item?.fee ? (Number(item?.fee) * 100).toFixed(2) : 0} %</>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

const optionSx = {
  img: {
    maxWidth: "40px",
    maxHeight: "40px",
  },
};

export default SelectPayments;
