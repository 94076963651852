import Box from "@mui/material/Box";
import { observer } from "mobx-react";

import ChartLoading from "./ChartLoading";
import ChartIntervalButtons from "./ChartIntervalButtons";
import ChartGraph from "./ChartGraph";
import { useAppStore } from "@/stores";
import { Theme } from "@mui/material";

interface ChartProps {}

const Chart: React.FC<ChartProps> = observer(() => {
  const {
    dexStore: { baseCoin, quoteCoin },
  } = useAppStore();

  return (
    <Box className="boxCard" sx={boxCardSx}>
      {baseCoin?.ticker && quoteCoin?.ticker && (
        <Box sx={labelSx}>{`${baseCoin?.ticker}/${quoteCoin?.ticker}`}</Box>
      )}
      <ChartLoading />
      <ChartIntervalButtons />
      <ChartGraph />
    </Box>
  );
});

const boxCardSx = {
  position: "relative",
  pb: {
    xs: 1, // 8px padding for extra-small (mobile) screens
    sm: 2, // 16px padding for small screens and up
  },
  mt: {
    xs: 1, // 8px padding for extra-small (mobile) screens
    sm: 2, // 16px padding for small screens and up
  },
  overflow: "hidden",
};

const labelSx = (theme: Theme) => ({
  position: "absolute",
  top: "40px",
  left: "15px",
  padding: "5px",
  fontWeight: 400,
  fontSize: "12px",
  // color: theme.palette.text.grayBorder,
  zIndex: 1,
});

export default Chart;
