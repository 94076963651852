import React, { useMemo } from "react";
import { observer } from "mobx-react";

import { useAppStore } from "@/stores";
import Select from "@/components/common/select/Select";
import SelectOption from "@/components/common/select/SelectOption";
import SelectOptions from "@/components/common/select/SelectOptions";

interface SelectSendAssetProps {}

const SelectSendAsset: React.FC<SelectSendAssetProps> = observer(() => {
  const {
    swapStore: { assets, sendAsset, receiveAsset, setSendAsset },
  } = useAppStore();

  const filteredAsset = useMemo(() => {
    const filtered = receiveAsset
      ? assets.filter(
          (asset) =>
            asset.currencyHex !== receiveAsset?.currencyHex &&
            asset.issuer !== receiveAsset?.issuer
        )
      : assets;

    return filtered;
  }, [assets, receiveAsset]);

  const onSelect = (item: any) => {
    setSendAsset(item);
  };

  return (
    <Select value={<SelectOption item={sendAsset} title={sendAsset?.name} />}>
      <SelectOptions
        items={filteredAsset}
        handleSelect={onSelect}
        headerTitle={"Select Token"}
      />
    </Select>
  );
});

export default SelectSendAsset;
