import React, { useState, useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { debounce } from "lodash";

import { useAppStore } from "@/stores";

interface TradeOrderInputAmountProps {}

const TradeOrderInputAmount: React.FC<TradeOrderInputAmountProps> = observer(
  () => {
    const {
      dexStore: {
        baseCoin,
        tradeOrderAmount,
        setTradeOrderAmount,
        setTradeOrderMarketTotal,
      },
    } = useAppStore();

    const [inputValue, setInputValue] = useState<any>({});

    const _setTradeOrderMarketTotal = useCallback(
      debounce(setTradeOrderMarketTotal, 500),
      [setTradeOrderMarketTotal]
    ); // eslint-disable-line

    const _inputValue = useMemo(() => {
      return inputValue?.number === tradeOrderAmount
        ? inputValue?.string
        : tradeOrderAmount;
    }, [tradeOrderAmount, inputValue]);

    useEffect(() => {
      _setTradeOrderMarketTotal();
    }, [tradeOrderAmount]);

    const _handleChangeAmount = (e: any) => {
      let _value = e.target.value;

      if (_value === "") {
        setInputValue({ number: 0, string: undefined });
        setTradeOrderAmount(0);

        return;
      }

      if (_value === "," || _value === ".") {
        setInputValue({ number: 0, string: "0." });
        setTradeOrderAmount(0);

        return;
      }

      // const rgx = /^[0-9]*[.,]?[0-9]*$/;
      const rgx = /^(0|[1-9]\d*|(0|[1-9]\d*)[.,]\d*)$/;

      if (!rgx.test(_value)) return;
      _value = _value.replace(/,/g, ".");
      const _valueString = _value;

      _value = _value ? (_value >= 0 ? _value : _value * -1) : _value;

      const _valueNumber = _value || _value === 0 ? Number(_value) : undefined;

      setTradeOrderAmount(_valueNumber);

      setInputValue({ number: _valueNumber, string: _valueString });
    };

    return (
      <>
        <TextField
          id="trade-amount"
          fullWidth
          type={"text"}
          placeholder={"Amount"}
          autoComplete="off"
          sx={{
            marginBottom: 1,
            border: "1px solid #3B474F",
            borderRadius: "6.25px",
          }}
          value={_inputValue ?? ""}
          onChange={_handleChangeAmount}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{ marginTop: "1px" }}>
                {baseCoin?.ticker}
              </InputAdornment>
            ),
          }}
          inputProps={{
            pattern: "^[0-9]*[.,]?[0-9]*$",
            minLength: "1",
            maxLength: "79",
            inputMode: "decimal",
            autoCorrect: "off",
            spellCheck: "false",
          }}
        />
      </>
    );
  }
);

export default TradeOrderInputAmount;
