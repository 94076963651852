import React, { useEffect } from "react";
import { observer } from "mobx-react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Theme, useTheme } from "@mui/material/styles";
import moment from "moment";

import { useAppStore } from "@/stores";
import LoadingBox from "@/components/common/loading/LoadingBox";

// import { ReactComponent as OpenLinkIcon } from "@/assets/images/icons/openLink.svg";
// import { ReactComponent as OpenLinkDarkIcon } from "@/assets/images/icons/openLinkDark.svg";
import CheckSuccessIcon from "../../../assets/images/icons/checkSuccess.svg";
import { LoadingButton } from "@mui/lab";

interface OrdersClosedProps {}

const OrdersClosed: React.FC<OrdersClosedProps> = observer(() => {
  const theme = useTheme();
  const {
    dexStore: {
      baseCoin,
      quoteCoin,
      historyOrders,
      isHistoryOrdersLoading,
      getAccountOrdersHistory,
    },
    accountStore: { account, connectWallet },
  } = useAppStore();

  useEffect(() => {
    getAccountOrdersHistory();
  }, [getAccountOrdersHistory, baseCoin]);

  if (!account) {
    return (
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        py={2}
        px="8px"
      >
        <LoadingButton
          variant="onxrpPrimary"
          size="large"
          // loading={isTradeOrderSubmitting}
          sx={{
            py: "10px",
            // marginTop: 3,
            marginBottom: 1,
          }}
          onClick={connectWallet}
        >
          Connect Wallet
        </LoadingButton>
      </Box>
    );
  }

  if (!isHistoryOrdersLoading && historyOrders.length <= 0) {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        py={2}
        px={4}
        width="100%"
      >
        <Typography sx={{ textAlign: "center", width: "100%" }}>
          You don't have any open orders.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <LoadingBox isLoading={isHistoryOrdersLoading} />
      {historyOrders.map((l, index) => (
        <Grid
          container
          width="100%"
          justifyContent="space-between"
          borderBottom="1px solid #25333C"
          py={2}
          px={3}
          key={index}
        >
          <Grid item textAlign="left">
            <Typography sx={textOperationSx(theme, l.operation)} mb={1}>
              {l.operation}
            </Typography>
            <Typography sx={textDateSx} mb={1} textTransform="uppercase">
              {l?.date && moment.unix(l.date).format("MMM DD YYYY HH:mm")}
            </Typography>
            <Typography sx={textModeSx}>{l.mode}</Typography>
          </Grid>
          <Grid item textAlign="right">
            <Typography sx={textOperationSx(theme, l.operation)} mb={1}>
              {l.volume} {baseCoin?.ticker}
            </Typography>
            <Typography sx={textDateSx} mb={1}>
              {l.total} {quoteCoin?.ticker}
            </Typography>
            <Box sx={statusBoxSx}>
              {l.status === "Successful" ? (
                <img src={CheckSuccessIcon} alt="success" />
              ) : null}
              <Typography sx={textStatusSx(theme, l.status)}>
                {l.status}
                {/* <Box component="span" sx={linkSx}>
                  {l.hash && (
                    <a
                      href={"https://livenet.xrpl.org/transactions/" + l.hash}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {theme.palette.mode !== "dark" ? (
                        <OpenLinkIcon />
                      ) : (
                        <OpenLinkDarkIcon />
                      )}
                    </a>
                  )}
                </Box> */}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ))}
    </>
  );
});

const linkSx = {
  verticalAlign: "text-top",
  marginLeft: "2px",
  svg: {
    maxWidth: "12px",
  },
};

const textOperationSx = (theme: Theme, oper: string | undefined) => ({
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "12px",
  color:
    oper === "BUY"
      ? theme.palette.text.positiveFigure
      : theme.palette.text.negativeFigure,
});

const textDateSx = (theme: Theme) => ({
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "10px",
  color: theme.palette.text.lightGray,
});

const statusBoxSx = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "4px",
};

const textStatusSx = (theme: Theme, type: string | undefined) => ({
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "10px",
  color:
    type === "Successful"
      ? theme.palette.text.green
      : theme.palette.text.negativeFigure,
});

const textModeSx = (theme: Theme) => ({
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "10px",
  color: theme.palette.text.grayBorder,
});

export default OrdersClosed;
