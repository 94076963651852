import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useAppStore } from "@/stores";
import { formatNumber } from "@/helpers/formatter";
import { Theme } from "@mui/material";

const WalletHeaderBalances = observer(() => {
  const {
    dexStore: { baseCoin, quoteCoin },
    accountStore: { account, baseBalance, quoteBalance },
  } = useAppStore();

  // useEffect(() => {
  //   console.log(account, baseBalance, quoteBalance, "WalletHeaderBalances");
  // }, [account, baseBalance, quoteBalance]);

  return (
    <>
      {account && (
        <Box display="flex" justifyContent="center">
          {baseCoin && (
            <Box sx={baseSx}>
              <Typography>
                {formatNumber(baseBalance, 2, 2)} {baseCoin?.ticker}
              </Typography>
            </Box>
          )}
          <Box alignContent="center">|</Box>
          {quoteCoin && (
            <Box sx={quoteSx}>
              <Typography>
                {formatNumber(quoteBalance, 2, 2)} {quoteCoin?.ticker}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
});

const baseSx = (theme: Theme) => ({
  pr: 2,
  // borderRight: `1px solid ${theme.palette.text.lightGray}`,
  // width: "130px",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
});

const quoteSx = {
  pl: 2,
  // width: "130px",
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
};

const textSx = {
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "12px",
};

export default WalletHeaderBalances;
