import React, { useState, useMemo, useEffect } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import { Header, Footer } from "@onxrp/ui-common";

import getTheme from "@/styles/theme";
import DexPage from "@/components/pages/dex/DexPage";
import SwapPage from "@/components/pages/swap/SwapPage";
import OnRampPage from "@/components/pages/onRamp/OnRampPage";
import Wallet from "@/components/common/wallets/WalletModal";
import ConnectButton from "@/components/common/buttons/ConnectButton";
import FooterContainer from "../footer/FooterContainer";
import Header from "../header/Header";

const Layout: React.FC<any> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(() => {
    const modeQuery = searchParams.get("mode");

    if (modeQuery && modeQuery === "dark") {
      return true;
    }

    if (modeQuery && modeQuery === "light") {
      return false;
    }

    const mode = localStorage.getItem("onxrp_isDarkMode");
    const isDarkMode = mode ? JSON.parse(mode) === "dark" : false;

    return isDarkMode;
  });

  useEffect(() => {
    const modeQuery = searchParams.get("mode");

    if (modeQuery) {
      const _searchParams = searchParams;
      _searchParams.delete("mode");
      setSearchParams(_searchParams);
    }
  });

  const theme = useMemo(() => {
    return getTheme("dark");
  }, [isDarkTheme]);

  const onChangeColorTheme = () => {
    setIsDarkTheme((prevMode) => !prevMode);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <Header
          onChangeColorTheme={onChangeColorTheme}
          theme={theme}
          walletConnectBtn={<ConnectButton />}
        /> */}
        <Header />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<DexPage />} />
            <Route path="/swap" element={<SwapPage />} />
            <Route path="/onramp" element={<OnRampPage />} />
          </Routes>
        </div>

        {/* <Footer theme={theme} /> */}
        {/* <FooterContainer /> */}

        <Wallet />
      </ThemeProvider>
    </div>
  );
};

export default Layout;
