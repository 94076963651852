import { text } from "@fortawesome/fontawesome-svg-core";
import { red } from "@mui/material/colors";
import { createTheme, Theme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
  interface ButtonPaletteColor {
    main: string;
    contrastText: string;
    gradientStart?: string;
    gradientMiddle?: string;
    gradientEnd?: string;
    gradientStartDisabled?: string;
    gradientEndDisabled?: string;
    text?: string;
    textHover?: string;
    border?: string;
    selected?: string;
  }
  interface TypeText {
    primary: string;
    gray?: string;
    grayBorder?: string;
    lightGray?: string;
    button?: string;
    link?: string;
    linkFooter?: string;
    xpunk?: string;
    warning?: string;
    error?: string;
    modal?: string;
    red?: string;
    green?: string;
    negativeFigure?: string;
    positiveFigure?: string;
    bidds?: string;
  }
  interface TypeBackground {
    main: string;
    secondary?: string;
    light: string;
    white: string;
    dark: string;
    body: string;
    card?: string;
    chart?: string;
    input: string;
    tableHead: string;
    gradientStart: string;
    gradientMiddle: string;
    gradientEnd: string;
    icon: string;
    gradientModalStart: string;
    gradientModalEnd: string;
    modalBody: string;
    modalBodySecondary: string;
    backDrop: string;
    menuOverlay: string;
    button: string;
    loadingBox: string;
  }
  interface BorderPaletteColor {
    main: string;
    footer?: string;
    light?: string;
    blueOpacity?: string;
  }
  interface Palette {
    button: ButtonPaletteColor;
    border: BorderPaletteColor;
    background: TypeBackground;
    black: {
      main: string;
    };
    white: {
      main: string;
    };
    gray: {
      main: string;
      light: string;
      dark: string;
      xlight: string;
      darker: string;
    };
    purple: {
      main: string;
    };
    green: {
      main: string;
    };
    red: {
      main: string;
    };
    teal: {
      main: string;
    };
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    button: ButtonPaletteColor;
    border: BorderPaletteColor;
    black: {
      main: string;
    };
    white: {
      main: string;
    };
    gray: {
      main: string;
      light: string;
      dark: string;
      xlight: string;
      darker: string;
    };
    purple: {
      main: string;
    };
    green: {
      main: string;
    };
    red: {
      main: string;
    };
    teal: {
      main: string;
    };
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    button: true;
    text: true;
  }
  interface ButtonPropsVariantOverrides {
    onxrpPrimary: true;
    onxrpSecondary: true;
  }
}

const getTheme = (mode: "dark" | "light") => {
  const isDarkMode = mode === "dark";
  // A custom theme for this app
  let theme: Theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 767,
        md: 820,
        lg: 1180,
        xl: 1280,
        xxl: 1440,
      },
    },
    typography: {
      // Tell Material-UI what's the font-size on the html element is.
      htmlFontSize: 20,
    },
    palette: {
      mode: isDarkMode ? "dark" : "light",
      primary: {
        main: isDarkMode ? "#143539" : "#143539",
        // main: isDarkMode ? "rgba(148, 148, 160, 0.2)" : "#fff",
        light: "#0301f7",
        dark: "#143539",
      },
      secondary: {
        main: isDarkMode ? "#143539" : "#143539",
        light: "#61dafb",
        dark: "#21a1c4",
        // dark: isDarkMode ?  "#2A2C30": "#E7E7EE",
      },
      gray: {
        main: "#3B474F",
        light: "#b1b1b1",
        dark: "#383434",
        xlight: isDarkMode ? "#e0e0e0" : "#c9c9c9",
        darker: isDarkMode ? "#e0e0e0" : "#161632",
      },
      black: {
        main: "#3B474F",
      },
      white: {
        main: "#fff",
      },
      green: {
        main: "#62b547",
      },
      red: {
        main: "#eb344f",
      },
      purple: {
        main: "#143539",
      },
      teal: {
        main: "rgba(255,0,0)",
      },
      error: {
        main: red.A400,
      },
      background: {
        main: "11181D",
        // main: isDarkMode ? "#121212" : "#fff",
        // secondary: isDarkMode ? "#2A2C30" : "#E7E7EE",
        light: "#fff",
        white: "#fff",
        dark: "#121212",
        body: isDarkMode ? "#121212" : "#eee",
        card: isDarkMode ? "#2A2C30" : "#fff",
        chart: isDarkMode ? "#121212" : "#f6f6f6",
        input: isDarkMode ? "#262626" : "#f6f6f6",
        tableHead: isDarkMode ? "#e0e0e0" : "#0301f7",
        gradientStart: "",
        gradientMiddle: "",
        gradientEnd: "",
        icon: isDarkMode ? "#e0e0e0" : "#0301f7",
        gradientModalStart: "",
        gradientModalEnd: "",
        modalBody: isDarkMode ? "#161F25" : "#d6d6ef",
        modalBodySecondary: isDarkMode ? "#161F25" : "#161F25",
        backDrop: isDarkMode ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0.5)",
        menuOverlay: isDarkMode ? "#aaa" : "rgba(0,0,0,.39)",
        button: isDarkMode ? "#0a1013" : "#0a1013",
        loadingBox: isDarkMode ? "#000" : "#e1e1ee",
      },
      text: {
        primary: isDarkMode ? "#ffffff" : "#ffffff",
        secondary: isDarkMode ? "#ffffff" : "#eee",
        disabled: "#fff",
        button: isDarkMode ? "#ffffff" : "#f5f5f2",
        gray: isDarkMode ? "#e0e0e0" : "#807e8e",
        grayBorder: isDarkMode ? "#79858C" : "#79858C",
        lightGray: isDarkMode ? "#79858C" : "#79858C",
        link: isDarkMode ? "#e0e0e0" : "#0301f7",
        linkFooter: isDarkMode ? "#e0e0e0" : "#171732",
        xpunk: isDarkMode ? "#e0e0e0" : "#0401f6",
        warning: isDarkMode ? "#e0e0e0" : "#0201ed",
        error: isDarkMode ? "#e0e0e0" : "#aa3b1a",
        modal: isDarkMode ? "#ffffff" : "#ffffff",
        red: isDarkMode ? "#D24B6B" : "#D24B6B",
        green: isDarkMode ? "#049B93" : "#049B93",
        negativeFigure: isDarkMode ? "#D24B6B" : "#D24B6B",
        positiveFigure: isDarkMode ? "#7DB583" : "#7DB583",
        bidds: isDarkMode ? "#00FFF0" : "#00FFF0",
      },
      button: {
        main: "#161F25",
        // selected: isDarkMode ? "#EEEEEE" : "#fff",
        contrastText: "#FFFFFF",
        gradientStart: "",
        gradientMiddle: "transparent",
        gradientEnd: "",
        gradientStartDisabled: "",
        gradientEndDisabled: "",
        text: isDarkMode ? "#e0e0e0" : "#171732",
        textHover: isDarkMode ? "#171732" : "#171732",
        // border: isDarkMode ? "#eee" : "#0301f7",
      },
      border: {
        main: "transparent",
        footer: "transparent",
        light: "transparent",
        blueOpacity: "transparent",
      },
    },
  });

  theme = createTheme(theme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontFamily: `"Monda", "Helvetica", "Arial", "Monda", sans-serif`,
            // fontSize: "16px",
            background: "#0A1013",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            counterReset: "phase-counter",
          },
          header: {
            "& .MuiTypography-body1": {
              fontSize: "0.75rem",
              [theme.breakpoints.up("xl")]: {
                fontSize: "0.875rem",
              },
            },
            "& .MuiButtonBase-root": {
              fontSize: "12px",
              letterSpacing: "0px",
              padding: "11px 32px",
              borderRadius: "10px",
              // boxShadow: `2px 1000px 1px ${isDarkMode ? "#121212" : "#e2e1ef"} inset`,
              "&:hover": {
                background: "#1A464B",
                color: "#00FFF0",
              },
              "&:active": {
                background: "#0E2527",
                color: "#00FFF0",
              },
              [theme.breakpoints.up("lg")]: {
                fontSize: "0.875rem",
                letterSpacing: "0px",
              },
              [theme.breakpoints.down("md")]: {
                fontSize: "0.5rem",
                minWidth: "50%",
                marginBottom: "16px",
                padding: "7px 21px",
              },
              "&.theme-mode-btn": {
                display: "none",
                color: isDarkMode ? "#1B1B2F" : "#FFFFFF",
                boxShadow: "none",
                "&:hover": {
                  background: isDarkMode ? "#EBEBEB" : "#000000",
                  color: isDarkMode ? "#1B1B2F" : "#FFFFFF",
                  boxShadow: "none",
                },
              },
            },
          },
          "& .boxCard": {
            borderRadius: "10px",
            backgroundColor: "#161F25",
            // boxShadow: "0px 10px 20px rgba(23, 23, 50, 0.06)",
            [theme.breakpoints.down("sm")]: {
              // borderWidth: "0",
            },
          },
          img: {
            maxWidth: "100%",
          },
          "& .modal-body": {
            "& .MuiInput-root": {
              backgroundColor: "#161F25",
              color: "#FFF",
            },
            "& .MuiTypography-root": {
              color: "#FFF",
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "#FFF",
            fontFamily: `"Monda", "Helvetica", "Arial", "Monda", sans-serif`,
            fontWeight: "400",
            "&.xpunks-logo": {
              span: {
                "&:last-child": {
                  color: "#FFF",
                },
              },
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // boxShadow: "7px 7px 7px 1px rgb(0 0 0 / 15%)",
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: theme.unstable_sx({
            boxShadow: "none",
            padding: { xs: "21px 0", md: "32px 0", lg: "0" },
            backgroundImage: "none",
            backgroundColor: "transparent",
            "& .MuiToolbar-root": {
              minHeight: { xs: 0, md: "80px" },
            },
          }),
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: theme.unstable_sx({
            maxWidth: {
              xs: "calc(100% - 10px)",
              sm: "calc(100% - 40px)",
              md: "calc(100% - 40px)",
              lg: "calc(100% - 40px)",
              xl: "calc(100% - 40px)",
              xxl: "1440px",
            },
            "&.page": {
              padding: 0,
            },
          }),
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            "& th::first-of-Type": {
              backgroundColor: "#0a1013",
              color: "#FFF",
              fontWeight: "bold",
              borderRadius: "10px",
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            color: "#FFF",
            fontFamily: `"Monda", "Helvetica", "Arial", "Monda", sans-serif`,
            fontWeight: 400,
            position: "unset",
            padding: "10px",
            wordBreak: "break-word",
            fontSize: "10px",
            border: "none",
            borderRadius: "0px",
            "&.table-cell-sticky": {
              padding: "6px 10px",
              position: "sticky",
              left: 0,
              background: "transparent",
              "&.table-cell-sticky-header": {
                background: "transparent",
                [theme.breakpoints.down("md")]: {
                  paddingLeft: "12px",
                  // backgroundImage: isDarkMode
                  //   ? `linear-gradient(90deg, ${theme.palette.background.gradientStart}, ${theme.palette.background.gradientEnd})`
                  //   : `linear-gradient(90deg, ${theme.palette.background.gradientStart}, #4d14ce)`,
                },
              },
            },
            "&.table-cell-sticky-header": {
              color: "#FFF",
              position: "sticky",
              minWidth: "65px",
            },
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "onxrpPrimary" },
            style: {
              position: "relative",
              padding: "9px 24px",
              textTransform: "uppercase",
              color: "#00FFF0",
              boxShadow: "none",
              // backgroundImage: `linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, ${theme.palette.button.gradientStart}, ${theme.palette.button.gradientEnd})`,
              // border: "1px solid",
              borderColor: "transparent",
              borderRadius: "6.25px",
              overflow: "hidden",
              transitionDuration: "0ms",
              font: "12px",
              "&:hover": {
                color: "#00FFF0",
                background: "#1A464B",
                // borderColor: "#00FFF0",
              },
              "&:active": {
                color: "#00FFF0",
                background: "#0E2527",
                // borderColor: "#00FFF0",
              },
              "&.Mui-disabled": {
                color: "#1A464B",
                background: "#143539",
                width: "100%",
                "&:hover": {
                  color: "#00FFF0",
                  background: "#143539",
                  width: "100%",
                },
              },
              "& .MuiLoadingButton-loadingIndicator": {
                color: "#00FFF0",
              },
            },
          },
          {
            props: { variant: "onxrpSecondary" },
            style: {
              position: "relative",
              padding: "9px 24px",
              textTransform: "uppercase",
              boxShadow: "none",
              color: "#FFF",
              background: "#049B93",
              // border: "1px solid",
              borderColor: "#049B93",
              borderRadius: "10px",
              overflow: "hidden",
              transitionDuration: "0ms",
              "&:hover": {
                color: "#FFF",
                background: "#07CABF",
                borderColor: "#07CABF",
              },
              "&.Mui-disabled": {
                borderColor: "#049B93",
                color: "#FFF",
                background: "#049B93",
                "&:hover": {
                  color: "#FFF",
                  background: "#07CABF",
                },
              },
              "& .MuiLoadingButton-loadingIndicator": {
                color: "#3B474F",
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            fontFamily: `"Monda", "Helvetica", "Arial", "Monda", sans-serif`,
            fontWeight: "bold",
            borderRadius: "6.25px",
            background: "#143539",
            backgroundColor: "#143539",
            width: "100%",
            color: "#00FFF0",
            // border: "1px solid transparent",
            // backgroundOrigin: "border-box",
            // backgroundClip: "content-box, border-box",
            // boxShadow: `2px 1000px 1px ${theme.palette.background.button} inset`,
            "&:hover": {
              boxShadow: "none",
              color: "#1A464B",
            },
            "&.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.26)",
            },
          },
          containedSecondary: {
            boxShadow: "none",
            color: "#00FFF0",
            "&:hover": {
              color: "#00FFF0",
              //boxShadow: `1px 1000px 1px ${"#1A464B"} inset`,
            },
          },
          text: {
            border: "none",
            background: "none",
            padding: 0,
            color: "#00FFF0",
            boxShadow: "none",
            "&:hover": {
              background: "none",
              color: "#00FFF0",
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            fontFamily: `"Monda", "Helvetica", "Arial", "Monda", sans-serif`,
            [theme.breakpoints.down("sm")]: {
              display: "flex",
            },

            "&.tabs-content button": {
              backgroundColor: "#11181D",
              color: "#79858C",
              borderTop: "none",
              borderBottomRightRadius: "0",
              borderBottomLeftRadius: "0",
              // borderTopLeftRadius: "0",
              lineHeight: "4",
              zIndex: 1,
              "&:hover": {
                backgroundColor: "#1D282F",
                color: "#FFF",
              },
            },
            "&.tabs-content .Mui-selected": {
              backgroundColor: "transparent",
              color: "#FFF",
              zIndex: 100,
            },
            "&.chart-period-buttons button": {
              padding: "2px 10px",
              backgroundColor: "#11181D",
              color: "#79858C",
              "&:hover": {
                backgroundColor: "#1D282F",
                color: "#FFF",
              },
              [theme.breakpoints.down("sm")]: {
                padding: "8px",
                fontSize: "10px",
                width: "100%",
              },
            },
            "&.chart-period-buttons .Mui-selected": {
              backgroundColor: "#161F25",
              color: "#fff",
            },
            "&.market-mode button": {
              // border: isDarkMode ? "none" : '1px solid #E7E7EE',
              backgroundColor: "#11181D",
              color: "#79858C",
              borderRadius: "4px",
              fontSize: "10px",
              "&:hover": {
                backgroundColor: "#1D282F",
                color: "#FFF",
              },
            },
            "&.market-mode .Mui-selected": {
              backgroundColor: "#25333C",
              color: "#FFF",
            },
            "&.amount-buy-buttons button": {
              borderRadius: "4px",
              // backgroundColor: "#11181D",
              // color: "#3B474F",
              "&:hover": {
                backgroundColor: "#1D282F",
                color: "#FFF",
              },
              "&:focus, &:active": {
                backgroundColor: "#1D282F", // Keep hover background color
                color: "#FFF", // Keep white text color
                outline: "none", // Remove default focus outline if any
              },
              "&.active": {
                backgroundColor: "#1D282F", // Maintain the lighter background color
                color: "#FFF", // Keep the text white
              },
            },

            "&.amount-buy-buttons .Mui-selected": {
              backgroundColor: "rgba(37,51,60,0.8)",
              color: "#FFF",
            },
            "&.coin-trade-tabs button": {
              backgroundColor: "#161F25",
              fontWeight: 600,
              ".MuiTypography-root": {
                color: "#79858C",
              },
              img: {
                // filter: isDarkMode ? "invert(0)" : "invert(1)",
              },
              "&:hover, &.Mui-selected": {
                backgroundColor: "#1D282F",
                [theme.breakpoints.down("sm")]: {
                  // backgroundColor: isDarkMode ? "#121212" : "#efefef",
                },
                ".MuiTypography-root": {
                  color: "#FFF",
                },
                img: {
                  // filter: "invert(1)",
                },
              },
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            border: "0",
            color: "#FFF",
            backgroundColor: "#161F25",
            fontFamily: `"Monda", "Helvetica", "Arial", "Monda", sans-serif`,
            fontWeight: "bold",
            "&.Mui-selected": {
              color: "#FFF",
              backgroundColor: "#161F25",
              "&:hover": {
                backgroundColor: "#1D282F",
              },
            },
            "&:hover": {
              backgroundColor: "#1D282F",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: "#161F25",
            color: "#FFF",
            borderRadius: "6.25px !important",
            heigh: "48px",
            fontFamily: `"Monda", "Helvetica", "Arial", "Monda", sans-serif`,
            fontSize: "12px",
            lineHeight: "12px",
            fontWeight: "bold",

            // border: "1px solid #3B474F",
            letterspacing: "0px",
            padding: "0px 12px",
            "& input": {
              padding: "14px 0px 14px 0px",
              maxHeigh: "48px",
              fontSize: "12px",
              color: "#79858C", // Initial color when the input is not focused
              textAlign: "right",

              "&:focus": {
                color: "#FFF", // Change the color to white when the input is focused
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "& .Mui-disabled": {
              WebkitTextFillColor: `${theme.palette.black.main} !important`,
            },
            "&.coin-pairs": {
              backgroundColor: "transparent",
              "&:before": {
                border: "0 !important",
              },
              "& .MuiSelect-select": {
                paddingBottom: 0,
              },
              "& .MuiListItemText-root": {
                margin: 0,
              },
              "& .MuiTypography-root": {
                color: "white",
                fontSize: "1.2rem",
              },
              "& .MuiSvgIcon-root": {
                color: "#FFF",
              },
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            "& .MuiTypography-root": {
              color: theme.palette.gray.main,
              fontSize: "0.6rem",
              marginRight: "10px",
              textAlign: "center",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: theme.palette.white.main,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            "& .MuiBackdrop-root": {
              // background: "rgba(0,0,0,0.5) !important",
              opacity: "1 !important",
              // opacity: "0.9!important",
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            "&.pairs-search": {
              ".MuiInputBase-input": {
                backgroundColor: "#161F25",
                borderRadius: "8px !important",
                // fontSize: "0.875rem",
                fontWeight: 400,
                paddingLeft: "12px",
                textAlign: "left",

                "::placeholder": {
                  color: "#fff",
                },
              },
            },
            "&.select-search": {
              ".MuiInputBase-input": {
                backgroundColor: "#161F25",
                borderRadius: "8px !important",
                fontSize: "0.875rem",
                fontWeight: 400,
                paddingLeft: "12px",
                textAlign: "left",

                "::placeholder": {
                  color: "#fff",
                },
              },
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "#FFF",
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: "#FFF",
            textDecoration: "none",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            margin: 0,
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#161F25",
            },
            "&::-webkit-scrollbar": {
              background: "transparent",
              width: "4px",
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            padding: "15px 24px",
          },
        },
      },
    },
  });

  return theme;
};

export default getTheme;
