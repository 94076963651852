import React from "react";
import { observer } from "mobx-react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import { useAppStore } from "@/stores";
import { IOffer, IOrder } from "@/types";

const modalBodyStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 40px)",
  maxWidth: "500px",
  bgcolor: "background.modalBody",
  borderRadius: "24px",
  padding: {
    xs: "16px",
    md: "16px 32px 32px",
  },
  "& .warning": {
    color: "text.link",
  },
};

interface OrderCancelConfirmProps {
  offer?: IOffer;
  cancelCallback: () => void;
}

const OrderCancelConfirm: React.FC<OrderCancelConfirmProps> = observer(
  ({ offer, cancelCallback }) => {
    const {
      dexStore: { baseCoin, quoteCoin, cancelOrder },
    } = useAppStore();

    const _onClickConfirm = () => {
      if (!offer) return;

      const order: IOrder = {
        sequence: offer?.sequence,
      };

      cancelOrder(order);
      cancelCallback();
    };

    const _onClickCancel = () => {
      cancelCallback();
    };

    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modal-body"
          open={!!offer}
          closeAfterTransition
          disableAutoFocus
        >
          <Fade in={!!offer}>
            <Box sx={modalBodyStyle}>
              <Grid
                container
                justifyContent="center"
                textAlign="center"
                rowSpacing={2}
              >
                <Grid item xs={12} mb={1} mt={1}>
                  <Typography fontSize="1.2rem" textAlign={"left"}>
                    Do you want to cancel your order?
                  </Typography>
                </Grid>
                <Grid item xs={12} mb={3}>
                  <Typography
                    fontSize="1rem"
                    fontWeight={400}
                    textAlign={"left"}
                  >
                    {offer?.volume +
                      " " +
                      baseCoin?.ticker +
                      " for " +
                      offer?.total +
                      " " +
                      quoteCoin?.ticker}
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="onxrpPrimary"
                      fullWidth
                      onClick={_onClickConfirm}
                    >
                      Confirm
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="onxrpSecondary"
                      fullWidth
                      onClick={_onClickCancel}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </>
    );
  }
);

export default OrderCancelConfirm;
