import { useContext, createContext } from "react";

import FavoritesStore from "./FavoritesStore";
import SwapStore from "./SwapStore";
import OnRampStore from "./OnRampStore";
import XRPLStore from "./XRPLStore";
import AccountStore from "./AccountStore";
import DexStore from "./DexStore";
import WalletStore from "./WalletStore";

class RootStore {
  public favoritesStore: FavoritesStore;
  public swapStore: SwapStore;
  public onRampStore: OnRampStore;
  public xrplStore: XRPLStore;
  public accountStore: AccountStore;
  public dexStore: DexStore;
  public walletStore: WalletStore;

  constructor() {
    this.xrplStore = new XRPLStore(this);
    this.accountStore = new AccountStore(this);
    this.walletStore = new WalletStore(this);
    this.dexStore = new DexStore(this);
    this.favoritesStore = new FavoritesStore(this);
    this.swapStore = new SwapStore(this);
    this.onRampStore = new OnRampStore(this);
  }
}

export const RootStoreContext = createContext<RootStore>(new RootStore());
export const useAppStore = () => useContext(RootStoreContext);
export default RootStore;
