import { Period, PeriodAlter } from "@/types";

export const defaultCoins = Object.freeze({
  xrp: {
    issuer: "",
    currencyHex: "XRP",
    ticker: "XRP",
    trustline: "",
    logo: "/assets/images/coin-icons/xrp.svg",
  },
  oxp: {
    id: "250fLXGZEunumIfhwnLO",
    trustline:
      "https://xumm.community/?issuer=rrno7Nj4RkFJLzC4nRaZiLF5aHwcTVon3d&currency=OXP&limit=250000000",
    totalSupply: "250,000,000",
    issuer: "rrno7Nj4RkFJLzC4nRaZiLF5aHwcTVon3d",
    discord: "https://discord.gg/pDMjFVX2Uj",
    logo: "https://firebasestorage.googleapis.com/v0/b/onxrp-21175.appspot.com/o/projects%2FONXRP%2Fonxrp%20logo.png?alt=media&token=c0001607-3c32-41f3-a833-c232625af546",
    order: "100",
    currencyHex: "OXP",
    banner:
      "https://firebasestorage.googleapis.com/v0/b/onxrp-21175.appspot.com/o/projects%2FONXRP%2Fonxrp%20banner%20compressed.jpeg?alt=media&token=4373c1ae-de27-4a56-b004-6bdbf1ee5a4d",
    twitter: "https://twitter.com/onxrpdotcom",
    projectName: "onXRP",
    description:
      "onXRP is a technology company driving innovation on the XRPL through the integration of a Decentralized Exchange, an NFT Marketplace and other community-centered services. It also produces and publishes content about the XRPL, NFTs and the crypto industry as a whole to educate the community and lower the barriers of entry for new blockchain entrants. Their utility token is widely used within the onXRP ecosystem.",
    status: "active",
    website: "https://onxrp.com",
    whitepaper: "https://onxrp.com/wp-content/uploads/2022/01/whitepaper.pdf",
    ticker: "OXP",
  },
  xpunk: {
    id: "YDpdF18lzFQAGYk2NaOT",
    website: "https://xpunks.club/",
    currencyHex: "5850554E4B000000000000000000000000000000",
    banner:
      "https://firebasestorage.googleapis.com/v0/b/onxrp-21175.appspot.com/o/projects%2FXPUNK%2Fxpunks-banner.jpg?alt=media&token=90351d24-a76b-4fc9-a2ba-edaafcf33bf3",
    twitter: "https://twitter.com/XRPLPUNKS",
    trustline:
      "https://xrpl.services/?issuer=rHEL3bM4RFsvF8kbQj3cya8YiDvjoEmxLq&currency=XPUNK&limit=5000",
    ticker: "XPUNK",
    description:
      "XPUNKS are willing to engage in constructive dialogue. We strive to be bigger than a brand. We want to utilize the capabilities of the XRPL, present and future, to it’s maximum potential. Undoubtedly, there will be setbacks, problems and technical challenges along the way as there always are with new projects and new technology. We plan to communicate transparently about these projects and we trust that we will overcome any challenges with the help of our dedicated community.",
    totalSupply: "5000",
    whitepaper:
      "https://xpunks.club/wp-content/uploads/2021/12/XpunksWhitepaper-Updated.pdf",
    order: "101",
    issuer: "rHEL3bM4RFsvF8kbQj3cya8YiDvjoEmxLq",
    logo: "https://firebasestorage.googleapis.com/v0/b/onxrp-21175.appspot.com/o/projects%2FXPUNK%2Fxpunks%20logo%20new.png?alt=media&token=0191d904-773b-4a71-b3cd-22b6ae54f099",
    status: "active",
    discord: "https://discord.gg/xpunks",
    projectName: "XPUNKS",
  },
});

export const PeriodEnum: { [key in PeriodAlter]?: Period } = Object.freeze({
  "1m": "1minute",
  "5m": "5minute",
  "15m": "15minute",
  "30m": "30minute",
  "1h": "1hour",
  "2h": "2hour",
  "3h": "3hour",
  "4h": "4hour",
  "6h": "6hour",
  "12h": "12hour",
  "1d": "1day",
  "3d": "3day",
  "1w": "7day",
});

export enum offerMode {
  buy = "BUY",
  sell = "SELL",
}

export enum tabsModeEnum {
  trade,
  book,
  orders,
}

export enum TradeModeEnum {
  swap,
  limit,
}

export enum TradeOrderModeEnum {
  market,
  limit,
}

export enum TradeOrderBuySellEnum {
  buy,
  sell,
}
