import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { useAppStore } from "@/stores";

interface TradeOrderInputTokenPriceProps {}

const TradeOrderInputTokenPrice: React.FC<TradeOrderInputTokenPriceProps> =
  observer(() => {
    const {
      dexStore: {
        baseCoin,
        quoteCoin,
        price,
        tradeOrderPricePerToken,
        setTradeOrderPricePerToken,
      },
    } = useAppStore();

    const [value, setValue] = useState<string | number | undefined>(
      tradeOrderPricePerToken
    );

    useEffect(() => {
      if (tradeOrderPricePerToken) return;

      setTradeOrderPricePerToken(price);
    });

    const _handleChangePricePerToken = (e: any) => {
      const rgx = /^[0-9]*[.,]?[0-9]*$/;
      let _value = e.target.value;
      if (!rgx.test(_value)) return;
      _value = _value.replace(/,/g, ".");

      _value = _value ? _value.replace(/,/g, ".") : 0;
      _value = _value ? (_value >= 0 ? _value : _value * -1) : _value;

      setValue(_value);

      setTradeOrderPricePerToken(Number(_value));
    };

    return (
      <>
        <TextField
          id="price-textfield"
          fullWidth
          type={"text"}
          placeholder={`Price per ${baseCoin?.ticker}`}
          autoComplete="off"
          sx={{
            marginBottom: 1,
            border: "1px solid #3B474F",
            borderRadius: "6.25px",
          }}
          value={value || tradeOrderPricePerToken || ""}
          onChange={_handleChangePricePerToken}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {quoteCoin?.ticker}
              </InputAdornment>
            ),
          }}
          inputProps={{
            pattern: "^[0-9]*[.,]?[0-9]*$",
            minLength: "1",
            maxLength: "79",
            inputMode: "decimal",
            autoCorrect: "off",
            spellCheck: "false",
          }}
        />
      </>
    );
  });

export default TradeOrderInputTokenPrice;
