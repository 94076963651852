import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Typography } from "@mui/material";
import { formatNumber } from "@/helpers/formatter";

interface TradeTabsProps {
  price: number | string | undefined;
  fontSize?: string;
}

const PriceXrp: React.FC<TradeTabsProps> = observer(({ price, fontSize }) => {
  let displayPrice;
  if (!price) {
    displayPrice = `--`;
  } else if (Number(price) < 0.01) {
    displayPrice = formatNumber(price ?? 0, 2, 6, false);
  } else {
    displayPrice = formatNumber(price ?? 0, 2, 2, false);
  }

  return (
    <Typography
      sx={(theme) => ({
        fontSize: `${fontSize ?? 10}px`,
        fontWeight: 700,
        lineHeight: `${fontSize ?? 10}px`,
        color: theme.palette.text.secondary,
      })}
    >
      {displayPrice} XRP
    </Typography>
  );
});

export default PriceXrp;
