import React from "react";
import { observer } from "mobx-react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { useAppStore } from "@/stores";

interface SignWalletProps {}

const SignWallet: React.FC<SignWalletProps> = observer(() => {
  const {
    walletStore: { deepLink, qrImgUrl },
  } = useAppStore();

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Grid container justifyContent="center" mt={3}>
            {qrImgUrl && (
              <img
                src={qrImgUrl}
                alt="Scan QR Code"
                style={{ maxWidth: "250px", width: "100%" }}
              />
            )}
          </Grid>
        </Grid>

        {deepLink && (
          <Grid
            className="top-nav__mobile-menu--wrapper"
            xs={12}

            // justifyContent="end"
            // position="absolute"
            // left="0px"
            // right="0px"
            // bottom="40px"
            // margin="16px"
          >
            <Grid
              container
              sx={{
                display: { md: "none", xs: "block" },
                // justifyContent: "center",
                // marginBottom: "16px",
                width: "100%",
              }}
            >
              <Button
                href={deepLink}
                title="Open in Xamman"
                target="_blank"
                fullWidth
                variant="onxrpPrimary"
                className="gradient-button"
                color="primary"
                sx={{
                  p: 1.5,
                  borderRadius: "6.25px",
                  textDecoration: "none",
                }}
              >
                Open in XAMMAN
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
});

export default SignWallet;
