import React from "react";
import { observer } from "mobx-react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import { useAppStore } from "@/stores";

const modalBodyStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 40px)",
  maxWidth: "500px",
  bgcolor: "background.modalBody",
  borderRadius: "24px",
  padding: {
    xs: "16px",
    md: "16px 32px 32px",
  },
  "& .warning": {
    color: "text.link",
  },
};

interface TagConfirmProps {}

const TagConfirm: React.FC<TagConfirmProps> = observer(() => {
  const {
    onRampStore: { walletTag, isTagConfirmOpen, setTagConfirmed, walletAddress },
  } = useAppStore();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal-body"
        open={isTagConfirmOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: {
            opacity: "0.9",
          },
          sx: {
            backgroundColor: "background.backDrop",
          },
        }}
        disableAutoFocus
      >
        <Fade in={isTagConfirmOpen}>
          <Box sx={modalBodyStyle}>
            <Grid container justifyContent="center" textAlign="center" rowSpacing={2}>
              <Grid item xs={12}>
                <Typography fontSize="1.2rem" textAlign={"left"}>
                  Wallet Address & Tag/Memo Check
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize="0.75rem" fontWeight="400" textAlign="left" className="warning">
                  Please check if the following wallet address & destination memo/tag are correct.
                </Typography>
              </Grid>
              <Grid item xs={12} mb={1}>
                <Typography fontSize="1rem" sx={{ wordBreak: "break-all" }}>
                  {walletAddress}
                </Typography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <Typography fontSize="1.5rem">{walletTag}</Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Button variant="onxrpPrimary" fullWidth onClick={() => setTagConfirmed(true)}>
                    Looks correct
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button variant="onxrpSecondary" fullWidth onClick={() => setTagConfirmed(false)}>
                    Change
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
});

export default TagConfirm;
