import React from "react";
import { observer } from "mobx-react";

import { useAppStore } from "@/stores";
import { IFiatData } from "@/types/IOnRamp";
import Select from "@/components/common/select/Select";
import SelectOption from "@/components/common/select/SelectOption";
import SelectOptions from "@/components/common/select/SelectOptions";

interface SelectFiatProps {}

const SelectFiat: React.FC<SelectFiatProps> = observer(() => {
  const {
    onRampStore: { fiats, fiatSelected, setFiat },
  } = useAppStore();

  const onSelect = (item: IFiatData) => {
    setFiat(item);
  };

  return (
    <>
      <Select value={<SelectOption item={fiatSelected} title={fiatSelected?.key} />}>
        <SelectOptions
          items={fiats}
          handleSelect={onSelect}
          headerTitle={"Select fiat currency"}
          optionComponent={(item) => <SelectOption item={item} title={item?.key + " " + item?.name} />}
        />
      </Select>
    </>
  );
});

export default SelectFiat;
