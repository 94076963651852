import { makeAutoObservable, runInAction } from "mobx";

import { ICurrency } from "@/types/ICurrency";
import RootStore from "./index";

interface ICoinsPair {
  quoteCoinName: string;
  baseCoinName: string;
}
export default class FavoritesStore {
  public rootStore: RootStore;
  public coins: ICurrency[] = [];
  public favorites: ICoinsPair[] = [];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
    const initFavorites = localStorage.getItem("onxrp_favorites");
    this.favorites = initFavorites ? JSON.parse(initFavorites) : [];
  }

  toggleFavorites(pair: ICoinsPair) {
    return this.isFavorites(pair)
      ? this.removeFavorites(pair)
      : this.addFavorites(pair);
  }

  addFavorites(pair: ICoinsPair) {
    this.favorites = [pair, ...this.favorites];
    this.saveFavorites();
  }

  removeFavorites(pair: ICoinsPair) {
    this.favorites = this.favorites.filter(
      (item) =>
        !(
          item.quoteCoinName === pair.quoteCoinName &&
          item.baseCoinName === pair.baseCoinName
        )
    );
    this.saveFavorites();
  }

  isFavorites(pair: ICoinsPair) {
    return this.favorites.some(
      (item) =>
        item.quoteCoinName === pair.quoteCoinName &&
        item.baseCoinName === pair.baseCoinName
    );
  }

  saveFavorites() {
    localStorage.setItem("onxrp_favorites", JSON.stringify(this.favorites));
  }

  async getCoins() {
    // if (this.coins.length > 0) return this.coins;
    // const coins = await this.rootStore.dexStore.getCoins();
    // const sort = coins.sort((a, _) =>
    //   a.ticker === "OXP" || a.ticker === "XPUNK" ? -1 : 1
    // );
    // const noDuplicates = [...new Set(sort)];
    // runInAction(() => {
    //   this.coins = noDuplicates;
    // });
  }
}
