import React from "react";

import LoadingText from "@/components/common/loading/LoadingText";

interface CoinPriceLoadingProps {}

const CoinPriceLoading: React.FC<CoinPriceLoadingProps> = () => {
  return <LoadingText isLoading={true} />;
};

export default CoinPriceLoading;
