import React from "react";
import { observer } from "mobx-react";
import Input from "@mui/material/Input";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import { useAppStore } from "../../../stores";

const inputWrapperSx = {
  position: "relative",
  width: "100%",
};

const inputSx = {
  background: "transparent",
  // border: "1px solid #c2c2c8",
  borderRadius: 24,
  padding: { xs: "10px 30% 10px 10px", sm: "15px 35% 15px 40px" },
  height: { xs: "36px", sm: "48px" },
  "& input": {
    fontSize: { xs: "0.75rem", sm: "0.875rem" },
    lineHeight: { xs: "1", sm: "1.2" },
    fontStyle: "normal",
    fontWeight: 700,
    color: "gray.darker",
    padding: 0,
  },
  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "input[type=number]": {
    MozAppearance: "textfield",
  },
  "&.error": {
    input: {
      color: "red.main",
    },
  },
};

const amountLoadingSx = {
  display: "flex",
  position: "absolute",
  top: 0,
  right: 16,
  width: "auto",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  span: {
    width: "20px",
    height: "20px",
  },
};

interface InputAmountCryptoProps {
  isError?: boolean;
}

const InputAmountCrypto: React.FC<InputAmountCryptoProps> = observer(({ isError }) => {
  const {
    onRampStore: { amountCrypto, amountCryptoError, setAmountCrypto, isWaitCoinAmount },
  } = useAppStore();

  const onChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const rgx = /^[0-9]*[.,]?[0-9]*$/;
    let _value = e.target.value;
    if (!rgx.test(_value)) return;
    _value = _value.replace(/,/g, ".");

    setAmountCrypto(_value);
  };

  return (
    <>
      <Box sx={inputWrapperSx}>
        <Input
          type="text"
          name="amount-fiat"
          placeholder="Receive amount... "
          disableUnderline
          fullWidth
          sx={inputSx}
          className={amountCrypto && isError ? "error" : ""}
          onChange={onChangeInput}
          value={amountCrypto}
          disabled
          inputProps={{
            pattern: "^[0-9]*[.,]?[0-9]*$",
            minLength: "1",
            maxLength: "79",
            inputMode: "decimal",
            autoCorrect: "off",
            spellCheck: "false",
          }}
        />
        {isWaitCoinAmount && (
          <Box sx={amountLoadingSx}>
            <CircularProgress size="20" />
          </Box>
        )}
      </Box>
      {amountCryptoError && (
        <Box mt={"8px"} pl={{ xs: "10px", sm: "20px" }} textAlign="left">
          <Typography
            sx={{
              fontSize: { xs: "12px", sm: "12pxm" },
              fontWeight: 700,
              lineHeight: "1rem",
              color: "text.error",
            }}
          >
            {amountCryptoError}
          </Typography>
        </Box>
      )}
    </>
  );
});

export default InputAmountCrypto;
