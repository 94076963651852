import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Loading from "./Loading";

interface LoadingBoxProps {
  title?: string;
  isLoading: boolean;
  sx?: any;
}

const LoadingBox: React.FC<LoadingBoxProps> = ({ isLoading, title, sx }) => {
  return (
    // <Box sx={() => loadingBoxSx(!!title || isLoading, sx)} className="loading-box">
    //   {title ? <Typography variant="h4">{title}</Typography> : <CircularProgress />}
    // </Box>

    <Box
      sx={() => loadingBoxSx(!!title || isLoading, sx)}
      className="loading-box"
    >
      {title ? (
        <Typography variant="h4">{title}</Typography>
      ) : (
        <Loading width="40px" height="40px" />
      )}
    </Box>
  );
};

const loadingBoxSx = (isLoading: boolean, sx?: any) => ({
  display: isLoading ? "flex" : "none",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "40vh",
  "&:after": {
    position: "absolute",
    content: "''",
    width: "100%",
    height: "100%",
    zIndex: "101",
    backgroundColor: "background.loadingBox",
    opacity: "0.2",
  },
  span: {
    zIndex: 102,
  },
  ...(sx && {
    ...sx,
  }),
});

export default LoadingBox;
