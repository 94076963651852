import React from "react";
import { observer } from "mobx-react";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { useTheme } from "@mui/material/styles";

import { TradeModeEnum } from "@/stores/data/dexData";
import { useAppStore } from "@/stores";

interface TradeTabsProps {}

const TradeTabs: React.FC<TradeTabsProps> = observer(() => {
  const {
    dexStore: { tradeMode, setTradeMode },
  } = useAppStore();
  const theme = useTheme();

  const _changeMode = (e: any) => {
    const mode = e?.target?.value;
    if (!mode) return;

    setTradeMode(Number(mode));
  };

  return (
    <>
      <ToggleButtonGroup
        fullWidth
        value={tradeMode}
        exclusive
        onChange={_changeMode}
        className="tabs-content"
        sx={{ maxHeight: "48px" }}
      >
        <ToggleButton
          value={TradeModeEnum.swap}
          sx={{
            borderTopLeftRadius: {
              xs: "6.25px !important",
              sm: "10px !important",
            },
            borderTopRightRadius: {
              xs: "6.25px !important",
              sm: "10px !important",
            },
            "&.Mui-selected": {
              borderRight: `1px solid ${theme.palette.border.main}`,
            },
          }}
        >
          Swap
        </ToggleButton>
        <ToggleButton
          value={TradeModeEnum.limit}
          sx={{
            borderTopLeftRadius: {
              xs: "6.25px !important",
              sm: "10px !important",
            },
            borderTopRightRadius: {
              xs: "6.25px !important",
              sm: "10px !important",
            },
            "&.Mui-selected": {
              // Styles for the selected state of the Limit tab
            },
          }}
        >
          Limit
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
});

export default TradeTabs;
