import React, { useEffect } from "react";
import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Button from "@mui/lab/LoadingButton";
import { Theme, useTheme } from "@mui/material/styles";
import { ProjectsButtons } from "@onxrp/ui-common";

import { useAppStore } from "@/stores";

import SelectFiat from "./SelectFiat";
import SelectCrypto from "./SelectCrypto";
import SelectPayments from "./SelectPayments";
import InputAmountFiat from "./InputAmountFiat";
import InputAmountCrypto from "./InputAmountCrypto";
import InputWalletAddress from "./InputWalletAddress";
import InputWalletTag from "./InputWalletTag";
import TagConfirm from "./TagConfirm";

const formWrapper = (theme: Theme) => ({
  position: "relative" as const,
  padding: { xs: "16px", sm: "24px 48px" },
  marginBottom: "24px",
  fontFamily: `"Monda", "Helvetica", "Arial", "Monda", sans-serif`,
  fontWeight: "bold",
  background: "transparent",
  color: theme?.palette?.button?.text,
  border: "1px solid",
  borderColor: "border.main",
  borderRadius: "24px",
  overflow: "hidden",
  "& h4": {
    fontSize: { xs: "1.2rem", sm: "1.5rem" },
  },
  h6: {
    fontSize: { xs: "0.875rem", sm: "1rem" },
  },
});

const warningIconSx = {
  display: "inline-flex",
  width: "18px",
  height: "18px",
  border: "1px solid",
  borderRadius: "24px",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "10px",
};

const warningCardSx = {
  backgroundColor: "background.card",
  borderRadius: "24px",
  padding: { xs: "12px", sm: "20px" },
  textAlign: "left",
};

const loadingBoxSx = (isLoading: boolean) => ({
  display: isLoading ? "flex" : "none",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "40vh",
  "&:after": {
    position: "absolute",
    content: "''",
    width: "100%",
    height: "100%",
    zIndex: "101",
    backgroundColor: "background.modalBodySecondary",
    opacity: "0.5",
  },
  span: {
    zIndex: 102,
  },
});

interface OnRamperProps {}

const OnRamper: React.FC<OnRamperProps> = () => {
  const theme = useTheme();

  const {
    onRampStore: { init, isInitLoading, canPlaceOrder, placeOrder, isOrderSubmitting, setPrivacy, setWalletAddress },
    accountStore: { account },
  } = useAppStore();

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (!account) return;

    setWalletAddress(account);
  }, [account, setWalletAddress]);

  return (
    <>
      <Container sx={{ padding: { xs: 0 } }}>
        <Box display="flex" sx={{ mb: { xs: 3, md: 4 } }}>
          <ProjectsButtons activeLink="onRamp" theme={theme} />
        </Box>
      </Container>
      <Container sx={{ maxWidth: { md: "758px" }, mb: 6, mt: { xs: 1, sm: 3 } }} maxWidth={false} className="page">
        <Box sx={formWrapper}>
          <Box sx={() => loadingBoxSx(isInitLoading)}>
            <CircularProgress />
          </Box>
          <Grid>
            <Grid container mb={2}>
              <Grid item xs={12}>
                <Typography align="left" mb={1} variant="h6">
                  You pay
                </Typography>
              </Grid>
              <Grid container item xs={12} alignItems="flex-start" justifyContent={"space-between"}>
                <Grid item xs>
                  <InputAmountFiat />
                </Grid>
                <Grid item pl={2}>
                  <SelectFiat />
                </Grid>
              </Grid>
            </Grid>
            <Grid container mb={2}>
              <Grid item xs={12}>
                <Typography align="left" mb={1} variant="h6">
                  You receive
                </Typography>
              </Grid>
              <Grid container item xs={12} alignItems="center" justifyContent={"space-between"}>
                <Grid item xs>
                  <InputAmountCrypto />
                </Grid>
                <Grid item pl={2}>
                  <SelectCrypto />
                </Grid>
              </Grid>
            </Grid>
            <Grid container mb={2}>
              <Typography align="left" mb={1} variant="h6">
                Receiving wallet address
              </Typography>
              <InputWalletAddress />
            </Grid>
            <Grid container justifyContent="center" mb={2}>
              <Box sx={warningCardSx}>
                <Grid item xs={12}>
                  <Typography color="text.warning" fontSize={{ xs: "0.625rem", sm: "0.75rem" }} fontWeight="400">
                    <Box component="span" sx={warningIconSx}>
                      !
                    </Box>{" "}
                    When sending XRP to an exchange, double check if the memo/tag is correct. If it’s not or missing it
                    may result in a loss of funds.
                  </Typography>
                </Grid>
              </Box>
            </Grid>
            <Grid container mb={2}>
              <Typography align="left" mb={1} variant="h6">
                Wallet Tag/Memo
              </Typography>
              <InputWalletTag />
            </Grid>
            <Grid container mb={2}>
              <Typography align="left" mb={1} variant="h6">
                Select Payment Service
              </Typography>
              <SelectPayments />
            </Grid>
            <Grid container mb={2} alignItems="baseline">
              <Grid item xs={"auto"}>
                <Checkbox onChange={setPrivacy} />
              </Grid>
              <Grid item xs textAlign="left">
                <Link
                  href="https://banxa.com/wp-content/uploads/2022/06/Customer-Terms-and-Conditions-1-July-2022-1.pdf?_ga=2.52316010.21262661.1657010280-588576560.1657010280"
                  target="_blank"
                  fontSize={{ xs: "0.75rem", sm: "0.875rem" }}
                  fontWeight={"400"}
                >
                  I have read and agree to the Terms & Conditions.
                </Link>
              </Grid>
            </Grid>
            <Grid container mb={2}>
              <Button
                fullWidth
                variant="onxrpPrimary"
                className="gradient-button"
                sx={{
                  p: { xs: 1, sm: 1.5 },
                  borderRadius: "24px",
                }}
                disabled={!canPlaceOrder}
                onClick={placeOrder}
                loading={isOrderSubmitting}
              >
                Place order
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container>
        <TagConfirm />
      </Container>
    </>
  );
};

export default observer(OnRamper);
