import { makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { io, Socket } from "socket.io-client";
import { ReactSession } from "react-client-session";
import { ISignInData, IOrder } from "@/types";

import RootStore from "./index";

export default class WalletStore {
  public rootStore: RootStore;
  public uuid?: string;
  public qrImgUrl?: string;
  public deepLink?: string;
  public isWaitingWallet: boolean = false;
  public isSigningWallet: boolean = false;
  public payloadInterval?: any;

  private lastResponseData?: any;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
  }

  setIsWaitingWallet(state: boolean) {
    if (this.isSigningWallet) {
      this.isWaitingWallet = false;
      return;
    }
    this.isWaitingWallet = state;
  }

  setUUID(uuid: string) {
    this.uuid = uuid;
  }

  resetStates() {
    this.cancelPayload();
    this.lastResponseData = undefined;
    this.qrImgUrl = undefined;
    this.deepLink = undefined;
    this.uuid = undefined;
    this.isSigningWallet = false;
    this.isWaitingWallet = false;
  }

  async closeWalletRequest() {
    await this.cancelPayload();
    this.isWaitingWallet = false;
    this.isSigningWallet = false;
  }

  async signIn() {
    try {
      const { data } = await axios.post(`/api/account/connect-wallet`);

      if (data?.uuid) {
        runInAction(() => {
          this.lastResponseData = data;
          this.uuid = data?.uuid;
          this.qrImgUrl = data?.refs?.qr_png;
          this.deepLink = data?.next?.always;
          this.isSigningWallet = true;
          this.isWaitingWallet = false;
          this.subscribeSignIn(data?.uuid);
          this.rootStore.accountStore.getBalances();
        });

        return data;
      }
    } catch (err) {
      Sentry.captureException(err);

      runInAction(() => {
        this.resetStates();
      });

      return err;
    }
  }

  subscribeSignIn(uuid?: string) {
    let socket: Socket<{ signin: (data: any) => void }, any>;
    const _uuid = uuid || this.uuid;
    if (_uuid) {
      socket = io(`${process.env.REACT_APP_AXIOS_ROOT_URL}`, {
        transports: ["websocket"],
      });
      socket.emit("subscribe-uuid", _uuid);
      socket.on("signin", (data: ISignInData) => {
        if (!data.expired) {
          if (data.account) {
            ReactSession.set("xpunk-account", data.account);
            ReactSession.set("xpunk-userToken", data.issuedUserToken);

            runInAction(() => {
              this.rootStore.accountStore.loadAccount();
              this.resetStates();
            });
          }
        } else {
          // expired
        }
        socket.off("signin");
      });
    }
  }

  async createOffer(offer: any) {
    try {
      const { data } = await axios.post("/api/trade/create-offer", offer);

      if (data?.uuid) {
        runInAction(() => {
          this.lastResponseData = data;
          this.uuid = data?.uuid;
          this.qrImgUrl = data?.refs?.qr_png;
          this.deepLink = data?.next?.always;
          this.isWaitingWallet = data?.pushed;
          this.isSigningWallet = !data?.pushed;
          this.getPayload(data?.uuid, this.rootStore.accountStore.getBalances);
        });

        return data;
      }
    } catch (err) {
      Sentry.captureException(err);

      runInAction(() => {
        this.resetStates();
      });

      return err;
    }
  }

  async cancelOffer(order: IOrder) {
    try {
      const { data } = await axios.post("/api/trade/cancel-offer", {
        userToken: this.rootStore.accountStore.userToken,
        account: this.rootStore.accountStore.account,
        sequence: order.sequence,
      });

      if (data?.uuid) {
        runInAction(() => {
          this.lastResponseData = data;
          this.uuid = data?.uuid;
          this.qrImgUrl = data?.refs?.qr_png;
          this.deepLink = data?.next?.always;
          this.isWaitingWallet = true;
          this.getPayload(data?.uuid);
        });

        return data;
      }
    } catch (err) {
      Sentry.captureException(err);

      runInAction(() => {
        this.resetStates();
      });

      return err;
    }
  }

  async getPayload(uuid: string, callback?: () => void) {
    if (!uuid && !this.uuid) return;

    const _uuid = uuid || this.uuid;

    this.setIsWaitingWallet(true);
    this.uuid = _uuid;
    this.clearPayloadInterval();

    this.payloadInterval = setInterval(() => {
      axios
        .get(`/api/trade/get-payload/${_uuid}`)
        .then((res) => {
          const resolved_at = res?.data?.response?.resolved_at;

          runInAction(() => {
            if (resolved_at) {
              this.resetStates();
              this.clearPayloadInterval();

              if (callback) callback();
            } else {
            }
          });
        })
        .catch((err) => {
          runInAction(() => {
            this.resetStates();
          });
        });
    }, 3000);
  }

  async cancelPayload(uuid?: string) {
    if (!this.uuid && !uuid) return;

    const _uuid = uuid || this.uuid;

    try {
      await axios.get(`/api/trade/cancel-payload/${_uuid}`);

      runInAction(() => {
        this.resetStates();
        this.clearPayloadInterval();
      });
    } catch (err) {
      Sentry.captureException(err);

      runInAction(() => {
        this.resetStates();
      });
    }
  }

  clearPayloadInterval() {
    if (!this.payloadInterval) return;

    clearInterval(this.payloadInterval);
  }
}
