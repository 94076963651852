import { autorun, makeAutoObservable, runInAction } from "mobx";
import axios from "axios";
import { isValidClassicAddress, isValidXAddress } from "ripple-address-codec";
//@ts-ignore
import platform from "platform";

import RootStore from "./index";
import { IChainData, IFiatData, ICoinData, IPaymentData, IBanxaRequest } from "@/types/IOnRamp";

export default class onRampStore {
  public rootStore: RootStore;
  public chain?: IChainData;
  public fiats?: IFiatData[];
  public fiatsError: string = "";
  public fiatSelected?: IFiatData;
  public coins?: ICoinData[];
  public coinsError: string = "";
  public coinSelected?: ICoinData;
  public payments?: IPaymentData[];
  public paymentsError: string = "";
  public paymentSelected?: IPaymentData;
  public amountFiat: string = "";
  public amountFiatError: string = "";
  public amountCrypto: string = "";
  public amountCryptoError: string = "";
  public walletAddress: string = "";
  public walletAddressError: string = "";
  public walletTag: string = "";
  public walletTagError: string = "";
  public isTagConfirmOpen: boolean = false;
  public isTagConfirmed: boolean = false;
  public isOrderSubmitting: boolean = false;
  public isInitLoading: boolean = true;
  public isWaitCoinAmount: boolean = false;
  public isCheckedPrivacy: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;

    // autorun(async () => {
    //   const coinAmount = await this.getCoinAmount();
    //   this.setAmountCrypto(coinAmount);
    // });

    autorun(async () => {
      await this.loadPayments();
    });
  }

  async init() {
    await Promise.all([this.loadFiats(), this.loadCoins()]);
    this.setDefaultPair();
    this.isInitLoading = false;
  }

  setDefaultPair() {
    if (!this.coins || !this.fiats) {
      return;
    }

    const defaultFiatKey = "EUR";
    const defaultCoinKey = "XRP";

    const fiatSelected = this.fiats.find((item) => item.key === defaultFiatKey);
    const coinSelected = this.coins.find((item) => item.key === defaultCoinKey);

    if (fiatSelected && coinSelected) {
      this.fiatSelected = fiatSelected;
      this.coinSelected = coinSelected;
    }
  }

  get canPlaceOrder() {
    return this.amountFiat && this.amountCrypto && this.walletAddress && this.isCheckedPrivacy;
  }

  setFiat(item: IFiatData) {
    this.fiatSelected = item;
  }

  setCoin(item: ICoinData) {
    this.coinSelected = item;
  }

  setPayment(item: IPaymentData) {
    this.paymentSelected = item;
    this.validateFiatAmount();
    this.setCoinAmount();
  }

  async setAmountFiat(value: string) {
    this.amountFiat = value;
    this.validateFiatAmount();
  }

  public validateFiatAmount() {
    if (!this.paymentSelected?.min || !this.paymentSelected?.max || !this.amountFiat) return;

    if (
      Number(this.amountFiat) < Number(this.paymentSelected?.min) ||
      Number(this.amountFiat) > Number(this.paymentSelected?.max)
    ) {
      this.amountFiatError = `Orders for your selected payment type must be between ${this.paymentSelected?.min} and ${this.paymentSelected?.max}`;
      return;
    }

    this.amountFiatError = "";
  }

  // TODO: Make it private
  setAmountCrypto(value: string) {
    this.amountCrypto = value;
  }

  setWalletAddress(value: string) {
    this.walletAddress = value;

    if (this?.coinSelected?.key === "XRP") {
      this.walletAddressError = !this.isValidXRPAddress(value) ? "This is not a valid XRPL address" : "";
    }
  }

  setWalletTag(value: string) {
    this.walletTag = value;
    this.isTagConfirmed = false;
  }

  setTagConfirmed(value: boolean) {
    this.isTagConfirmed = value;
    this.isTagConfirmOpen = false;

    if (value === true) {
      this.placeOrder();
    }
  }

  setChain(chain: IChainData) {
    this.chain = chain;
  }

  setPrivacy() {
    this.isCheckedPrivacy = !this.isCheckedPrivacy;
  }

  async placeOrder() {
    if (!this.fiatSelected?.key || !this.coinSelected?.key) {
      if (!this.fiatSelected?.key) {
        this.fiatsError = "Fiat is not selected";
      }
      if (!this.coinSelected?.key) {
        this.fiatsError = "Coin is not selected";
      }

      return;
    }

    if (!this.isTagConfirmed) {
      this.isTagConfirmOpen = true;

      return;
    }

    this.isOrderSubmitting = true;

    const formData: IBanxaRequest = {
      account_reference: this.walletAddress,
      source: this.fiatSelected?.key,
      target: this.coinSelected?.key,
      wallet_address: this.walletAddress,
      return_url_on_success: window.location.href,
      source_amount: this.amountFiat,
      payment_method_id: this.paymentSelected?.key,
    }

    if (this.walletTag) {
      formData.wallet_address_tag = this.walletTag;
    }

    try {
      const response = await axios.post("/api/onramp/orders", formData);

      const checkoutUrl = response?.data?.data?.order?.checkout_url;

      if (checkoutUrl != null) {
        const isSafari: boolean = platform.name === "Safari";

        window.open(checkoutUrl, isSafari ? "_self" : "_blank");
      }
    } catch (error) {
    } finally {
      this.isOrderSubmitting = false;
    }
  }

  public setIsWaitingCoinAmount(value: boolean) {
    this.isWaitCoinAmount = value;
  }

  public async setCoinAmount() {
    this.setIsWaitingCoinAmount(true);
    const coinAmount = await this.getCoinAmount();
    this.setAmountCrypto(coinAmount);
    this.setIsWaitingCoinAmount(false);
  }

  private async getCoinAmount() {
    if (!this.fiatSelected?.key || !this.coinSelected?.key || !this.paymentSelected?.key || this.amountFiat === "")
      return " ";

    const response = await axios.get(
      `/api/onramp/calculation/${this.fiatSelected?.key}/${this.coinSelected?.key}/${this.amountFiat || 0}`
    );

    const prices = response?.data?.data?.prices?.find(
      (price: any) => price.payment_method_id === this.paymentSelected?.key
    );

    return (prices?.coin_amount || "0") as string;
  }

  private async loadFiats() {
    const response = await axios.get("/api/onramp/fiats");
    const fiatsData = response?.data?.data?.fiats;
    if (fiatsData != null) {
      const fiats: IFiatData[] = fiatsData.map((d: any) => ({
        key: d.fiat_code,
        name: d.fiat_name,
        symbol: d.fiat_symbol,
        img: `https://github.com/transferwise/currency-flags/blob/master/src/flags/${d.fiat_code.toLowerCase()}.png?raw=true`,
      }));
      runInAction(() => {
        this.fiats = fiats;
      });
    }
  }

  private async loadCoins() {
    const response = await axios.get("/api/onramp/coins");
    const coinsData = response?.data?.data?.coins;
    if (coinsData != null) {
      const coins: ICoinData[] = coinsData.map((d: any) => ({
        key: d.coin_code,
        name: d.coin_name,
        img: `/assets/images/coin-icons/${d.coin_code.toLowerCase()}.svg`,
      }));
      runInAction(() => {
        this.coins = coins;
      });
    }
  }

  private async loadPayments() {
    if (this.fiatSelected?.key == null || this.coinSelected?.key == null) {
      return;
    }
    const response = await axios.get(`/api/onramp/payment-methods/${this.fiatSelected.key}/${this.coinSelected.key}`);
    const paymentsData = response?.data?.data?.payment_methods;
    if (paymentsData != null) {
      const payments: IPaymentData[] = paymentsData
        .filter((pa: any) => {
          return true;
          // if (pa.supported_agents == null) {
          //   return true;
          // }
          // return pa.supported_agents.find((sa: any) => sa.browser === platform.name.toLowerCase())
        })
        .map((p: any) => ({
          key: p.id,
          name: p.name,
          img: p.logo_url,
          fee: p.transaction_fees[0]?.fees[0]?.amount || undefined,
          feeType: p.transaction_fees[0]?.fees[0]?.type || undefined,
          min: p.transaction_limits[0]?.min || undefined,
          max: p.transaction_limits[0]?.max || undefined,
          fiatCode: p.transaction_limits[0]?.fiat_code || undefined,
          coinCode: p.transaction_limits[0]?.coin_code || undefined,
        }));
      runInAction(() => {
        this.payments = payments;
        this.paymentSelected = payments[0];
      });
    }
  }

  /**
   * Validates that a given address is a valid X-Address or a valid classic
   * address.
   *
   * @param address - Address to validate.
   * @returns True if address is a valid X-Address or classic address.
   * @category Utilities
   */
  private isValidXRPAddress(address: string): boolean {
    return isValidXAddress(address) || isValidClassicAddress(address);
  }
}
