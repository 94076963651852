import React from "react";
import { observer } from "mobx-react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

import { useAppStore } from "@/stores";

interface TradeOrderSetPercentProps {}

const TradeOrderSetPercent: React.FC<TradeOrderSetPercentProps> = observer(
  () => {
    const {
      dexStore: { setPercentageAmount },
    } = useAppStore();

    const _setAmount = (value: any) => {
      if (!value) return;

      setPercentageAmount(value);
    };

    return (
      <>
        <ToggleButtonGroup
          fullWidth
          value={1}
          exclusive
          className="amount-buy-buttons"
          sx={{
            // background: `linear-gradient(90deg, ${theme.palette.button.gradientStart}, ${theme.palette.button.gradientEnd})`,
            borderRadius: "4px",
            padding: { xs: "1px", sm: "1px" },
            color: "#fff",
          }}
        >
          <ToggleButton
            value="0.25"
            onClick={() => _setAmount(0.25)}
            sx={{
              padding: "5px",
              borderTopLeftRadius: "3px",
              borderBottomLeftRadius: "3px",
              backgroundColor: "#25333C40",
            }}
          >
            25%
          </ToggleButton>
          <ToggleButton
            value="0.5"
            onClick={() => _setAmount(0.5)}
            sx={{
              padding: "5px",
              backgroundColor: "#25333C80",
            }}
          >
            50%
          </ToggleButton>
          <ToggleButton
            value="0.75"
            onClick={() => _setAmount(0.75)}
            sx={{
              padding: "5px",
              backgroundColor: "#25333CBF",
            }}
          >
            75%
          </ToggleButton>
          <ToggleButton
            value="1"
            onClick={() => _setAmount(1)}
            sx={{
              padding: "5px",
              borderTopRightRadius: "3px",
              borderBottomRightRadius: "3px",
              backgroundColor: "#25333C",
            }}
          >
            100%
          </ToggleButton>
        </ToggleButtonGroup>
      </>
    );
  }
);

export default TradeOrderSetPercent;
