import React from "react";
import { observer } from "mobx-react";

import { useAppStore } from "@/stores";
import LoadingBox from "@/components/common/loading/LoadingBox";
import zIndex from "@mui/material/styles/zIndex";

interface TradeLoadingProps {}

const SwapLoading: React.FC<TradeLoadingProps> = observer(() => {
  const {
    swapStore: { isInitLoading, isRateLoading },
  } = useAppStore();

  return (
    <LoadingBox sx={{ height: "50px", zIndex: "1000" }} isLoading={true} />
  );
});

export default SwapLoading;
