import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Loading from "./Loading";
interface LoadingBoxProps {
  isLoading: boolean;
}

const LoadingText: React.FC<LoadingBoxProps> = ({ isLoading }) => {
  // return <CircularProgress color="inherit" size={"1rem"} sx={() => loadingBoxSx(isLoading)} />;
  return <Loading width="20px" height="20px" />;
};

const loadingBoxSx = (isLoading: boolean) => ({
  display: isLoading ? "inline-flex" : "none",
  marginRight: "5px",
  verticalAlign: "text-bottom",
});

export default LoadingText;
