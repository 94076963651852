import React, { useMemo } from "react";
import { observer } from "mobx-react";
import Typography from "@mui/material/Typography";

import { useAppStore } from "@/stores";
import { formatNumber } from "@/helpers/formatter";
import { TradeOrderBuySellEnum } from "@/stores/data/dexData";

interface TradeOrderCoinsRateProps {}

const TradeOrderCoinsRate: React.FC<TradeOrderCoinsRateProps> = observer(() => {
  const {
    dexStore: {
      baseCoin,
      quoteCoin,
      tradeOrderBuySellMode,
      sellOffers,
      buyOffers,
    },
  } = useAppStore();

  const modePrice = useMemo(() => {
    // Determine the price based on the current trade mode
    if (
      tradeOrderBuySellMode === TradeOrderBuySellEnum.buy &&
      buyOffers.length > 0
    ) {
      return sellOffers[0]?.price; // Swap is analogous to the previous "buy"
    }

    if (
      tradeOrderBuySellMode === TradeOrderBuySellEnum.sell &&
      sellOffers.length > 0
    ) {
      return buyOffers[0].price; // Limit is analogous to the previous "sell"
    }

    return 0;
  }, [buyOffers, sellOffers, tradeOrderBuySellMode]);

  return (
    <>
      <Typography align="left" fontSize="0.7rem">
        1 {baseCoin?.ticker} = {modePrice ? formatNumber(modePrice, 2, 6, false, false, false) : "?"}{" "}
        {quoteCoin?.ticker}
      </Typography>
    </>
  );
});

export default TradeOrderCoinsRate;
