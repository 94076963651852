import { Period, PeriodAlter } from "@/types";
import { round } from "lodash";

export const formatNumber = (
  value: number | string,
  minDecimals = 2,
  maxDecimals = 6,
  isXRP: boolean = false,
  isUSD: boolean = false,
  useSIPrefix = true
) => {
  const formatter = Intl.NumberFormat("en-US", {
    style: "decimal",
    useGrouping: true,
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  });

  const num = value
    ? isXRP
      ? parseFloat(value.toString().replaceAll(",", "")) / 1_000_000
      : parseFloat(value.toString().replaceAll(",", ""))
    : 0;

  if (!num || num === 0) return "0";
  if (useSIPrefix) {
    if (num < 0.01) return formatter.format(num);
    if (num >= 0.01 && num < 1e3) return round(num, 2).toString();
    if (num >= 1 && num < 1e3 && isUSD) return num.toFixed(2);
    if (num >= 1 && num < 1e3) return round(num, 2).toString();
    if (num >= 1e3 && num < 1e4) return (num / 1e3).toFixed(2) + "k"; // 1_000 - 10_000
    if (num >= 1e4 && num < 1e5) return (num / 1e3).toFixed(1) + "k"; // 10_000 - 100_000
    if (num >= 1e5 && num < 1e6) return (num / 1e3).toFixed(0) + "k"; // 100_000 - 1_000_000
    if (num >= 1e6 && num < 1e7) return (num / 1e6).toFixed(2) + "M"; // 1_000_000 - 10_000_000
    if (num >= 1e7 && num < 1e8) return (num / 1e6).toFixed(1) + "M"; // 10_000_000 - 100_000_000
    if (num >= 1e8 && num < 1e9) return (num / 1e6).toFixed(0) + "M"; // 100_000_000 - 1_000_000_000
    if (num >= 1e9 && num < 1e10) return (num / 1e9).toFixed(0) + "G"; // 1_000_000_000 - 1_000_000_000
    if (num >= 1e10 && num < 1e11) return (num / 1e9).toFixed(0) + "G"; // 10_000_000_000 - 100_000_000_000
    if (num >= 1e11 && num < 1e12) return (num / 1e9).toFixed(0) + "G"; // 100_000_000_000 - 1_000_000_000_000
    if (num >= 1e12) return (num / 1e12).toFixed(0) + "T"; // gtn 1_000_000_000_000
  }
  
  return formatter.format(num);
};

export const formatAlterPeriod = (period: Period) => {
  const periodObj: { [key in Period]?: PeriodAlter } = {
    "1minute": "1m",
    "5minute": "5m",
    "30minute": "30m",
    "1hour": "1h",
    "2hour": "2h",
    "3hour": "3h",
    "4hour": "4h",
    "6hour": "6h",
    "12hour": "12h",
    "1day": "1d",
    "3day": "3d",
    "7day": "1w",
  };

  return periodObj[period];
};
