import React, { useMemo } from "react";
import { observer } from "mobx-react";

import { useAppStore } from "@/stores";
import { TradeOrderModeEnum } from "@/stores/data/dexData";
import { formatNumber } from "@/helpers/formatter";
import { Typography } from "@mui/material";

interface TradeOrderInputTotalProps {}

const TradeOrderInputTotal: React.FC<TradeOrderInputTotalProps> = observer(
  () => {
    const {
      dexStore: {
        quoteCoin,
        tradeOrderMode,
        tradeOrderMarketTotal,
        tradeOrderLimitTotal,
      },
    } = useAppStore();

    const _total: number | string = useMemo(() => {
      return tradeOrderMode === TradeOrderModeEnum.limit
        ? formatNumber(tradeOrderLimitTotal, 2, 6, false, false, false)
        : formatNumber(tradeOrderMarketTotal, 2, 6, false, false, false);
    }, [tradeOrderMarketTotal, tradeOrderLimitTotal, tradeOrderMode]);

    return (
      <>
        <div id="xrp-textfield" className="xrp-textfield">
          <Typography
            sx={(theme) => ({
              fontSize: "12px",
              fontWeight: 700,
              lineHeight: "12px",
              color: theme.palette.text.grayBorder,
            })}
          >
            Total
          </Typography>
          <Typography
            sx={(theme) => ({
              fontSize: "12px",
              fontWeight: 700,
              lineHeight: "12px",
              color: theme.palette.text.secondary,
            })}
          >
            {`${_total} ${quoteCoin?.ticker}`}
          </Typography>
        </div>
      </>
    );
  }
);

export default TradeOrderInputTotal;
