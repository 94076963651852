import React from "react";
import { observer } from "mobx-react";
import Input from "@mui/material/Input";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useAppStore } from "../../../stores";

const inputWrapperSx = {
  position: "relative",
  width: "100%",
};

const inputSx = {
  background: "transparent",
  border: "1px solid #c2c2c8",
  borderRadius: "24px",
  padding: { xs: "10px 10% 10px 10px", sm: "15px 40px 15px 40px" },
  height: { xs: "36px", sm: "48px" },
  "& input": {
    fontSize: { xs: "0.75rem", sm: "0.875rem" },
    lineHeight: { xs: "1", sm: "1.2" },
    fontStyle: "normal",
    fontWeight: 700,
    color: "gray.darker",
    padding: 0,
  },
};

interface InputWalletTagProps {}

const InputWalletTag: React.FC<InputWalletTagProps> = observer(() => {
  const {
    onRampStore: { walletTag, walletTagError, setWalletTag },
  } = useAppStore();

  const onChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const rgx = /^[0-9]*[.,]?[0-9]*$/;
    let _value = e.target.value;
    if (!rgx.test(_value)) return;
    _value = _value.replace(/,/g, ".");

    setWalletTag(_value);
  };

  return (
    <>
      <Box sx={inputWrapperSx}>
        <Input
          type="text"
          placeholder="Enter your wallet tag..."
          disableUnderline
          fullWidth
          sx={inputSx}
          onChange={onChangeInput}
          value={walletTag || ""}
          inputProps={{
            pattern: "^[0-9]*[.,]?[0-9]*$",
            minLength: "1",
            maxLength: "79",
            inputMode: "decimal",
            autoCorrect: "off",
            spellCheck: "false",
          }}
        />
      </Box>
      {walletTagError && (
        <Box mt={"4px"} pl={"40px"} textAlign="left">
          <Typography
            sx={{
              fontSize: "0.75rem",
              fontWeight: 700,
              lineHeight: "1rem",
              color: "text.error",
            }}
          >
            {walletTagError}
          </Typography>
        </Box>
      )}
    </>
  );
});

export default InputWalletTag;
