import React, { useMemo } from "react";
import { observer } from "mobx-react";

import { useAppStore } from "@/stores";
import Select from "@/components/common/select/Select";
import SelectOption from "@/components/common/select/SelectOption";
import SelectOptions from "@/components/common/select/SelectOptions";

interface SelectReceiveAssetProps {}

const SelectReceiveAsset: React.FC<SelectReceiveAssetProps> = observer(() => {
  const {
    swapStore: { assets, receiveAsset, sendAsset, setReceiveAsset },
  } = useAppStore();

  const filteredAsset = useMemo(() => {
    return sendAsset ? assets.filter((asset) => asset.currencyHex !== sendAsset?.currencyHex) : assets;
  }, [assets, sendAsset]);

  const onSelect = (item: any) => {
    setReceiveAsset(item);
  };

  return (
    <>
      <Select value={<SelectOption item={receiveAsset} title={receiveAsset?.name} />}>
        <SelectOptions items={filteredAsset} handleSelect={onSelect} headerTitle={"Select an asset"} />
      </Select>
    </>
  );
});

export default SelectReceiveAsset;
