import React from "react";

import TradeOrderInputAmount from "./TradeOrderInputAmount";
import TradeOrderInputTokenPrice from "./TradeOrderInputTokenPrice";
import TradeOrderCoinsRate from "./TradeOrderCoinsRate";

interface TradeOrderInputsProps {}

const TradeOrderInputs: React.FC<TradeOrderInputsProps> = () => {
  return (
    <>
      <TradeOrderInputAmount />
      <TradeOrderInputTokenPrice />
      <TradeOrderCoinsRate />
    </>
  );
};

export default TradeOrderInputs;
