import { autorun, makeAutoObservable, runInAction } from "mobx";
import axios from "axios";

import RootStore from "./index";
import { IAsset, ICurrency, offerModeType, IOffer } from "@/types";
// import { getCoins } from "@/helpers/firebaseActions";
import { getCoinsApi } from "@/helpers/apiActions";
import { offerMode, defaultCoins } from "./data/dexData";
import { isError } from "lodash";

const defaultAssetKeys = ["XRP", "OXP"];
export default class SwapStore {
  public rootStore: RootStore;
  public account?: string;
  public userToken?: string;
  public assets: IAsset[] = [];
  public sendAsset?: IAsset;
  public sendAssetBalance?: number;
  public receiveAsset?: IAsset;
  public receiveAssetBalance?: number;
  public priceRate?: number = 0;
  public sendAmount?: number;
  public receiveAmount?: number;
  public receiveAmountNoSlippage?: number;
  public slippage: number = 0;
  public isSubmitting?: boolean = false;
  public isInitLoading: boolean = true;
  public isRateLoading: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
    autorun(() => this.getAccount());
    this.getBalances();
  }

  getAccount() {
    this.account = this.rootStore.accountStore?.account;
    this.userToken = this.rootStore.accountStore?.userToken;
  }

  async getBalances() {
    await this.rootStore.accountStore.getBalances();
  }

  async setAssets(coins?: ICurrency[]) {
    if (this.assets.length !== 0) return;

    this.isInitLoading = true;
    const result = { coins: coins, isError: false };
    // this.rootStore.dexStore?.coins.length > 2
    //   ?
    //   : await getCoinsApi();
    // const coins = this.rootStore.dexStore?.coins;

    runInAction(() => {
      if (result.isError || !result.coins) return;
      // if (!coins || coins.length === 0) return;

      const _coins = [defaultCoins.xrp, ...result.coins];

      const assets: IAsset[] = _coins?.map(
        (item: any) =>
          ({
            key: item.currencyHex,
            name: item.ticker,
            // img: item.logo,
            img: "",
            currencyHex: item.currencyHex,
            issuer: item.issuer,
            ticker: item.ticker,
            trustline: item.trustline,
            // logo: item.logo,
            logo: "",
            verified: item.verified,
            name_long: item.name_long,
            isMeme: item.isMeme,
          } as IAsset)
      );

      this.assets = assets;
      this.setDefaultAssets();
      this.isInitLoading = false;
    });
  }

  setDefaultAssets() {
    if (!this.assets || (this.sendAsset && this.receiveAsset)) return;

    const sendAsset =
      this.assets.find((asset) => asset.ticker === defaultAssetKeys[0]) ||
      undefined;
    const receiveAsset =
      this.assets.find((asset) => asset.ticker === defaultAssetKeys[1]) ||
      undefined;

    if (!sendAsset || !receiveAsset) return;

    this.setSendAsset(sendAsset);
    this.setReceiveAsset(receiveAsset);
  }

  setSendAsset(asset: IAsset) {
    this.sendAsset = asset;
    this.getSendAssetBalance();
    this.getPriceRate();
  }

  setReceiveAsset(asset: IAsset) {
    this.receiveAsset = asset;
    this.getReceiveAssetBalance();
    this.getPriceRate();
  }

  setSlippage(value: number) {
    // console.log(this.slippage, "slippage");
    this.slippage = value;
    if (this.sendAmount) this.setSendAmount(this.sendAmount);
  }

  switchAssets() {
    if (!this.sendAsset || !this.receiveAsset) return;

    const _sendAsset = this.sendAsset;
    const _receiveAsset = this.receiveAsset;

    this.sendAsset = _receiveAsset;
    this.receiveAsset = _sendAsset;
    this.priceRate = 0;

    this.getSendAssetBalance();
    this.getReceiveAssetBalance();
    this.getPriceRate();
  }

  async getSendAssetBalance() {
    if (!this.sendAsset || !this.account) {
      this.sendAssetBalance = 0;
      return;
    }

    const balances = this.rootStore.accountStore.balances;

    const sendAssetLine = balances.find(
      (line) => line.currency === this.sendAsset?.currencyHex
    );
    const sendAssetBalance = sendAssetLine?.balance
      ? Number(sendAssetLine?.balance)
      : 0;

    runInAction(() => {
      this.sendAssetBalance = sendAssetBalance;
    });
  }

  async getReceiveAssetBalance() {
    if (!this.receiveAsset || !this.account) {
      this.receiveAssetBalance = 0;
      return;
    }

    const balances = this.rootStore.accountStore.balances;

    const receiveAssetLine = balances.find(
      (line) => line.currency === this.receiveAsset?.currencyHex
    );
    const receiveAssetBalance = receiveAssetLine?.balance
      ? Number(receiveAssetLine?.balance)
      : 0;

    runInAction(() => {
      this.receiveAssetBalance = receiveAssetBalance;
    });
  }

  async updateBalances() {
    this.getSendAssetBalance();
    this.getReceiveAssetBalance();
  }

  setSendAmount(amount: number) {
    this.sendAmount = amount || 0;

    // if (!this.priceRate || !this.sendAmount) {
    //   return (this.receiveAmount = 0);
    // }
    // this.getPriceRate();

    this.receiveAmount =
      this.priceRate && this.priceRate !== 0
        ? (this.sendAmount! * this.priceRate! * (100 - this.slippage)) / 100
        : 0; // Handle division by zero, return 0 or any fallback value

    this.receiveAmountNoSlippage =
      this.priceRate && this.priceRate !== 0
        ? this.sendAmount! * this.priceRate!
        : 0; // Handle division by zero, return 0 or any fallback value
  }

  async getAssetBalance({
    asset,
    account,
  }: {
    asset: IAsset;
    account: string;
  }) {
    try {
      const result: any = await axios.post("/api/account/balance/alt", {
        account: account,
        currency: asset.ticker,
        currencyHex: asset.currencyHex,
      });

      return !result.isError && result?.data.balance ? result?.data.balance : 0;
    } catch (error) {
      return 0;
    }
  }

  async getOrderBooks(asset: IAsset) {
    try {
      const result: any = await axios.post("/api/coins/get-orderbooks", {
        currencyHex: asset?.currencyHex,
        issuer: asset?.issuer,
        counterCurrencyHex: "XRP",
      });

      return {
        buyOffers: result.data.sellOffers,
        sellOffers: result.data.buyOffers,
      };
    } catch (err) {
      return {
        buyOffers: [],
        sellOffers: [],
      };
    }
  }

  async getPriceRate() {
    if (!this.sendAsset || !this.receiveAsset) return;

    this.isRateLoading = true;

    const [sendAssetRate, receiveAssetRate] = await this.getAssetRates();
    runInAction(() => {
      this.updatePriceRate(sendAssetRate, receiveAssetRate);
    });
  }

  private async getAssetRates() {
    const r1 = await this.getSendAssetToXRPPath();
    const r2 = await this.getXRPtoReceiveCoinPath();

    // const [r1, r2]: [any, any] = await Promise.all([
    //   sendAssetToXRPPath,
    //   XRPtoReceiveCoinPath,
    // ]);

    const sendAssetRate = await this.calculateSendAssetRate(r1);
    const receiveAssetRate = await this.calculateReceiveAssetRate(r2);

    return [sendAssetRate, receiveAssetRate];
  }

  private getSendAssetToXRPPath() {
    return this.sendAsset?.currencyHex !== "XRP"
      ? this.rootStore.xrplStore.getPathFind({
          sendCoin: this.sendAsset,
          account: this.account,
          sourceAmount: 1,
        })
      : () => {};
  }

  private getXRPtoReceiveCoinPath() {
    return this.receiveAsset?.currencyHex !== "XRP"
      ? this.rootStore.xrplStore.getPathFind({
          receiveCoin: this.receiveAsset,
          account: this.receiveAsset?.issuer,
          destinationAmount: -1,
          sourceAmount: 1,
        })
      : () => {};
  }

  private async calculateSendAssetRate(r1: any) {
    let sendAssetRate =
      this.sendAsset?.currencyHex !== "XRP"
        ? r1?.result?.alternatives?.[0]?.destination_amount / 1_000_000
        : 1;

    if (!sendAssetRate) {
      sendAssetRate = await this.getPriceRateFromBookOffers({
        baseCoin: this.sendAsset ?? defaultCoins.xrp,
        mode: offerMode.buy,
      });
    }

    return sendAssetRate;
  }

  private async calculateReceiveAssetRate(r2: any) {
    let receiveAssetRate =
      this.receiveAsset?.currencyHex !== "XRP"
        ? r2?.result?.alternatives?.[0]?.destination_amount?.value * 1_000_000
        : 1;

    if (!receiveAssetRate) {
      const bookSellPrice = await this.getPriceRateFromBookOffers({
        baseCoin: this.receiveAsset ?? defaultCoins.xrp,
      });

      receiveAssetRate = 1 / bookSellPrice;
    }

    return receiveAssetRate;
  }

  private updatePriceRate(sendAssetRate: number, receiveAssetRate: number) {
    if (!sendAssetRate || !receiveAssetRate) {
      this.priceRate = 0;
      this.receiveAmount = 0;
      this.isRateLoading = false;
      return;
    }

    this.priceRate = sendAssetRate * receiveAssetRate * 1;
    this.isRateLoading = false;

    if (this.sendAmount) {
      this.receiveAmount = this.priceRate * this.sendAmount;
      this.receiveAmountNoSlippage =
        (this.priceRate * this.sendAmount * (100 - this.slippage)) / 100;
    }
  }

  async getPriceRateFromBookOffers({
    baseCoin,
    quoteCoin = defaultCoins.xrp,
    mode = offerMode.sell,
  }: {
    baseCoin: ICurrency;
    quoteCoin?: ICurrency;
    mode?: offerModeType;
  }) {
    const results = await this.rootStore.xrplStore.getOffer(
      baseCoin,
      quoteCoin,
      mode
    );

    if (mode === offerMode.sell) {
      const sellOffers: IOffer[] = results.map((offer) => {
        // @ts-ignore
        const takerGetsAmount = offer.TakerGets?.value
          ? // @ts-ignore
            Number(offer.TakerGets.value)
          : Number(offer.TakerGets) / 1_000_000;
        // @ts-ignore
        const takerPaysAmount = offer.TakerPays?.value
          ? // @ts-ignore
            Number(offer.TakerPays.value)
          : Number(offer.TakerPays) / 1_000_000;

        const price = takerPaysAmount / takerGetsAmount;

        return {
          price: price,
          volume: takerGetsAmount,
          total: takerPaysAmount,
          sequence: offer.Sequence,
          owner: offer.Account,
        };
      });

      return sellOffers?.[0]?.price || 0;
    }

    if (mode === offerMode.buy) {
      const buyOffers: IOffer[] = results.map((offer) => {
        // @ts-ignore
        const takerGetsAmount = offer.TakerGets?.value
          ? // @ts-ignore
            Number(offer.TakerGets.value)
          : Number(offer.TakerGets) / 1_000_000;
        // @ts-ignore
        const takerPaysAmount = offer.TakerPays?.value
          ? // @ts-ignore
            Number(offer.TakerPays.value)
          : Number(offer.TakerPays) / 1_000_000;

        const price = takerGetsAmount / takerPaysAmount;

        return {
          price: price,
          volume: takerPaysAmount,
          total: takerGetsAmount,
          sequence: offer.Sequence,
          owner: offer.Account,
        };
      });

      return buyOffers?.[0]?.price || 0;
    }

    return 0;
  }

  sendSwap({
    account,
    userToken,
    mode,
  }: {
    account: string;
    userToken: string;
    mode: "buy" | "sell";
  }) {
    if (
      !this.sendAsset ||
      !this.receiveAsset ||
      !this.priceRate ||
      !this.sendAmount ||
      !this.receiveAmount
    )
      return;

    this.isSubmitting = true;
    const rateRange = 1;

    const value =
      this.sendAsset.currencyHex === "XRP"
        ? Math.floor(this.sendAmount * 1_000_000)
        : this.sendAmount;
    const counterValue =
      this.receiveAsset.currencyHex === "XRP"
        ? Math.floor(this.receiveAmount * rateRange * 1_000_000)
        : (this.receiveAmount * rateRange).toFixed(6);

    axios
      .post("/api/trade/create-offer", {
        mode: mode,
        account: account,
        userToken: userToken,
        currency: this.sendAsset.currencyHex,
        issuer: this.sendAsset.issuer === "XRPL" ? "" : this.sendAsset.issuer,
        value: value,
        counterCurrency: this.receiveAsset.currencyHex,
        counterCurrencyIssuer:
          this.receiveAsset.issuer === "XRPL" ? "" : this.receiveAsset.issuer,
        counterValue: counterValue,
        marketOrder: true,
      })
      .then((res) => {
        runInAction(() => {
          this.isSubmitting = false;
          this.rootStore.walletStore.getPayload(
            res.data.uuid,
            this.rootStore.accountStore.getBalances
          );
          this.rootStore.walletStore.qrImgUrl = res.data?.refs?.qr_png;
          this.rootStore.walletStore.deepLink = res.data?.next?.always;
          this.rootStore.accountStore.getBalances();
        });
      })
      .catch((err) => {
        runInAction(() => {
          this.isSubmitting = false;
        });
      });
  }
}
