import React, { useState, useRef } from "react";
import { observer } from "mobx-react";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Theme,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { useAppStore } from "@/stores";
import { IOffer } from "@/types";

import OrderBookLoading from "./OrderBookLoading";
import OrderCancelConfirm from "./OrderCancelConfirm";
import OrderBooksTable from "./OrderBooksTable";

const TableStyled = styled(Table)(() => ({
  "& .MuiTableCell-stickyHeader": {
    position: "sticky",
  },
}));

// Custom styled tooltip

interface OrderBooksProps {}

const OrderBooks: React.FC<OrderBooksProps> = observer(() => {
  const {
    dexStore: {
      baseCoin,
      quoteCoin,
      buyOffers,
      sellOffers,
      offerSpread,
      cancelOrder,
    },
  } = useAppStore();

  const sellTable = useRef<any>();
  const [canceledOffer, setCancelOffer] = useState<IOffer>();

  return (
    <Box
      className="boxCard"
      sx={{
        py: {
          xs: 1, // 8px padding for extra-small (mobile) screens
          sm: 2, // 16px padding for large screens and up
        },
        position: "relative",
        minHeight: "528px",
        maxHeight: "528px",
      }}
    >
<OrderBookLoading />
<Typography
  sx={{
    ...titleSx, 
    fontSize: 10, 
    fontWeight: 700,
    marginBottom: 2,
    letterSpacing: 1,
    marginTop: {
      xs: 1,
      sm: 0,
    }
  }}
>
  ORDERBOOK
</Typography>
<TableContainer
  sx={{
    maxHeight: 185,
    fontSize: 10, // Font size for the TableContainer (if needed for table content)
    width: "auto",
  }}
      >
        <TableStyled
          aria-label="Order Books"
          size="small"
          className="table-body-spacing"
          stickyHeader
          ref={sellTable}
        >
          <TableHead
            sx={{
              background: "red",
            }}
          >
            <TableRow
              className="orderbook"
              sx={{
                position: "sticky",
                top: 0,
                "& th": {
                  color: "#ffffff",
                  background: "#25333C",
                  px: 3,
                },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  paddingLeft: 3,
                  border: "none",
                  color: "#79858C",
                  fontSize: "10px",
                }}
              >
                PRICE
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  border: "none",
                  color: "#79858C",
                  fontSize: "10px",
                }}
              >
                VOLUME
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  paddingRight: 3,
                  border: "none",
                  color: "#79858C",
                  fontSize: "10px",
                }}
              >
                TOTAL
              </TableCell>
            </TableRow>
          </TableHead>
        </TableStyled>
      </TableContainer>

      <OrderBooksTable
        typeTable="sell"
        baseCoin={baseCoin!}
        quoteCoin={quoteCoin!}
        offersTable={sellOffers}
      />

<Box sx={spreadBox}>
  <Typography
    sx={(theme) => ({
      marginRight: "8px",
      color: theme.palette.text.lightGray,
      fontSize: 10, // Add font size here for the ticker
    })}
  >
    {baseCoin?.ticker} SPREAD{" "}
  </Typography>
  <Typography
    sx={(theme) => ({
      fontWeight: 700,
      fontSize: 10, // Ensure font size is 10px for the spread as well
      color: theme.palette.text.secondary,
    })}
  >
    {offerSpread}
  </Typography>
</Box>

      <OrderBooksTable
        typeTable="buy"
        baseCoin={baseCoin!}
        quoteCoin={quoteCoin!}
        offersTable={buyOffers}
      />

      {canceledOffer && (
        <OrderCancelConfirm
          offer={canceledOffer}
          cancelCallback={() => setCancelOffer(undefined)}
        />
      )}
    </Box>
  );
});

const titleSx = (theme: Theme) => ({
  marginBottom: 2,
  fontWeight: 700,
  fontSize: "12px",
  color: theme.palette.text.secondary,
});

const spreadBox = () => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  paddingTop: "10px",
  paddingBottom: "10px",
  fontSize: "10px",
  background: "#25333C",
  zIndex: 5,
});

export default OrderBooks;
