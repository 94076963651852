import React from "react";
import { observer } from "mobx-react";
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useAppStore } from "@/stores";
import { tabsModeEnum } from "@/stores/data/dexData";

interface CoinTabsProps {}

const CoinTabs: React.FC<CoinTabsProps> = observer(() => {
  const theme = useTheme();
  const {
    dexStore: { tabsMode, setTabsMode },
  } = useAppStore();

  const _changeMode = (e: any) => {
    if (!e.target.value) return;

    const mode = Number(e.target.value);
    setTabsMode(mode);
  };

  return (
    <Box marginTop={2}>
      <ToggleButtonGroup
        className="coin-trade-tabs"
        fullWidth
        value={tabsMode}
        exclusive
        onChange={_changeMode}
        sx={{
          height: {
            xs: "60px", // height for xs screens
            sm: "72px", // height for sm and above screens
          },
          background: theme.palette.button.main,
          borderRadius: "8px",
          padding: "1px",
          overflow: "hidden",
        }}
      >
        <ToggleButton
          value={tabsModeEnum.trade}
          sx={{
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            display: "flex",
            flexDirection: "column",
            "&:hover img": {
              content: `url(/assets/images/Icon-Trading-Selected.svg)`,
            },
          }}
        >
          <Box
            component="img"
            src={
              tabsMode === tabsModeEnum.trade
                ? "/assets/images/Icon-Trading-Selected.svg"
                : "/assets/images/Icon-Trading.svg"
            }
            alt="Trade"
            sx={{
              pointerEvents: "none",
              padding: "4px",
              height: "24px",
              width: {
                xs: "20px", // width for extra small screens
                sm: "24px", // width for small and larger screens
              },
              borderRadius: "4px",
              backgroundColor: "#11181D",
            }}
          />
          <Typography
            sx={{
              color: theme.palette.text.primary,
              fontWeight: "600",
              fontSize: "0.7rem",
              mt: 0.5,
              pointerEvents: "none",
            }}
          >
            TRADE
          </Typography>
        </ToggleButton>

        <ToggleButton
          value={tabsModeEnum.book}
          sx={{
            display: "flex",
            flexDirection: "column",
            "&:hover img": {
              content: `url(/assets/images/Icon-Orderbook-Selected.svg)`,
            },
          }}
        >
          <Box
            component="img"
            src={
              tabsMode === tabsModeEnum.book
                ? "/assets/images/Icon-Orderbook-Selected.svg"
                : "/assets/images/Icon-Orderbook.svg"
            }
            alt="Orders"
            sx={{
              pointerEvents: "none",
              padding: "4px",
              height: "24px",
              width: {
                xs: "20px", // width for extra small screens
                sm: "24px", // width for small and larger screens
              },
              borderRadius: "4px",
              backgroundColor: "#11181D",
            }}
          />
          <Typography
            sx={{
              color: theme.palette.text.primary,
              fontWeight: "600",
              fontSize: "0.7rem",
              mt: 0.5,
              pointerEvents: "none",
            }}
          >
            BOOK
          </Typography>
        </ToggleButton>

        <ToggleButton
          value={tabsModeEnum.orders}
          sx={{
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            display: "flex",
            flexDirection: "column",
            "&:hover img": {
              content: `url(/assets/images/Icon-History-Selected.svg)`,
            },
          }}
        >
          <Box
            component="img"
            src={
              tabsMode === tabsModeEnum.orders
                ? "/assets/images/Icon-History-Selected.svg"
                : "/assets/images/Icon-History.svg"
            }
            alt="Logs"
            sx={{
              pointerEvents: "none",
              padding: "4px",
              height: "24px",
              width: {
                xs: "20px", // width for extra small screens
                sm: "24px", // width for small and larger screens
              },
              borderRadius: "4px",
              backgroundColor: "#11181D",
            }}
          />
          <Typography
            sx={{
              color: theme.palette.text.primary,
              fontWeight: "600",
              fontSize: "0.7rem",
              mt: 0.5,
              pointerEvents: "none",
            }}
          >
            ORDERS
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
});

export default CoinTabs;
