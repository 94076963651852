import React, { useMemo } from "react";
import { observer } from "mobx-react";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";

import { useAppStore } from "@/stores";
import {
  TradeModeEnum,
  TradeOrderModeEnum,
  TradeOrderBuySellEnum,
} from "@/stores/data/dexData";
import { Theme } from "@mui/material";

interface TradeOrderButtonsProps {}

const TradeOrderButtons: React.FC<TradeOrderButtonsProps> = observer(() => {
  const {
    dexStore: {
      baseCoin,
      buyOffers,
      sellOffers,
      tradeMode,
      tradeOrderMode,
      tradeOrderBuySellMode,
      tradeOrderAmount,
      tradeOrderMarketTotal,
      tradeOrderLimitTotal,
      isTradeOrderSubmitting,
      createOffer,
    },
    accountStore: {
      account,
      isBaseCoinTrustlineActive,
      baseBalance,
      quoteBalance,
      connectWallet,
    },
  } = useAppStore();

  const [isButtonDisable, error] = useMemo<[boolean, string]>(() => {
    if (tradeOrderMode === TradeOrderModeEnum.limit && !tradeOrderLimitTotal) {
      return [true, ""];
    }
    if (
      tradeOrderMode === TradeOrderModeEnum.market &&
      !tradeOrderMarketTotal
    ) {
      return [true, ""];
    }
    if (!tradeOrderAmount) {
      return [true, ""];
    }

    // Check balance conditions based on tradeMode
    if (
      tradeMode === TradeModeEnum.swap &&
      tradeOrderMode === TradeOrderModeEnum.market &&
      quoteBalance < tradeOrderMarketTotal
    ) {
      return [true, "Not enough balance"];
    }

    if (
      tradeMode === TradeModeEnum.swap &&
      tradeOrderMode === TradeOrderModeEnum.limit &&
      tradeOrderBuySellMode === TradeOrderBuySellEnum.sell &&
      quoteBalance < tradeOrderLimitTotal
    ) {
      return [true, "Not enough balance"];
    }

    if (
      tradeOrderBuySellMode === TradeOrderBuySellEnum.buy &&
      quoteBalance < tradeOrderLimitTotal
    ) {
      return [true, "Not enough balance"];
    }

    return [false, ""];
  }, [
    tradeOrderAmount,
    tradeOrderMarketTotal,
    tradeOrderLimitTotal,
    baseBalance,
    quoteBalance,
    tradeMode,
    tradeOrderMode,
  ]);

  const _createOffer = () => {
    createOffer();
  };

  const _createOfferDebounce = _.debounce(_createOffer, 500);

  if (!account) {
    return (
      <LoadingButton
        variant="onxrpPrimary"
        size="large"
        fullWidth
        // loading={isTradeOrderSubmitting}
        sx={{
          py: "10px",
          // marginTop: 3,
          marginBottom: 1,
        }}
        onClick={connectWallet}
      >
        Connect Wallet
      </LoadingButton>
    );
  }

  if (
    tradeOrderMode === TradeOrderModeEnum.market &&
    ((tradeMode === TradeModeEnum.swap && buyOffers.length === 0) ||
      (tradeMode === TradeModeEnum.limit && sellOffers.length === 0))
  ) {
    return (
      <Typography align="center" mt={2} fontSize="0.7rem">
        There are no open offers, please use the limit order instead
      </Typography>
    );
  }

  return (
    <>
      {error && <Typography sx={errorSx}>{error}</Typography>}
      <LoadingButton
        variant="onxrpPrimary"
        size="large"
        fullWidth
        disabled={isButtonDisable}
        loading={isTradeOrderSubmitting}
        sx={{
          py: "10px",
          // marginTop: 3,
          marginBottom: 1,
        }}
        onClick={_createOfferDebounce}
      >
        {/* {tradeMode === TradeModeEnum.swap ? "SWAP" : "LIMIT"} */}
        BUY
      </LoadingButton>
      {!isBaseCoinTrustlineActive && (
        <a
          href={baseCoin?.trustline}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Button
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            sx={{
              py: "10px",
              marginTop: 1,
              marginBottom: 1,
            }}
          >
            SET TRUSTLINE
          </Button>
        </a>
      )}
    </>
  );
});

const errorSx = (theme: Theme) => ({
  color: theme.palette.text.red,
});

export default TradeOrderButtons;
