import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useAppStore } from "@/stores";

import SelectReceiveAsset from "./SelectReceiveAsset";
import PriceRate from "./PriceRate";
import { Box, Input } from "@mui/material";
import PriceUsd from "../dex/PriceUsd";
import styled from "@emotion/styled";
import { set } from "lodash";

interface ReceiveFieldProps {
  usdRate?: number | null;
}

const ReceiveField: React.FC<ReceiveFieldProps> = observer(({ usdRate }) => {
  const {
    swapStore: {
      receiveAsset,
      sendAmount,
      receiveAmount,
      receiveAmountNoSlippage,
      priceRate,
      slippage,
    },
  } = useAppStore();

  const [valueInput, setValueInput] = useState<number>(0);

  useEffect(() => {
    if (sendAmount === 0) {
      setValueInput(0);
    } else if (receiveAsset?.currencyHex === "XRP") {
      setValueInput(receiveAmountNoSlippage! || 0);
    } else {
      setValueInput(sendAmount! || 0);
    }
  }, [
    receiveAsset,
    priceRate,
    receiveAmount,
    slippage,
    receiveAmountNoSlippage,
    sendAmount,
  ]);

  // useEffect(() => {
  //   console.log(valueInput, "valueInput");
  // }, [valueInput]);

  return (
    <>
      <Grid mb={1} sx={fieldSx}>
        <Box sx={row}>
          <Box sx={tokenSx}>
            <SelectReceiveAsset />
          </Box>

          <Box sx={inputSx}>
            <InputStyled
              id="swap-base-input"
              name="swap-quote"
              placeholder="0"
              value={receiveAmountNoSlippage?.toFixed(2)}
              // fullWidth
              disableUnderline
              autoComplete="off"
              type="text"
              // onClick={(e) => e.stopPropagation()}
              // onChange={onChangeInput}
              disabled={true}
              inputProps={{
                pattern: "^[0-9]*[.,]?[0-9]*$",
                minLength: "1",
                maxLength: "79",
                inputMode: "decimal",
                autoCorrect: "off",
                spellCheck: "false",
              }}
            />
          </Box>
        </Box>
        <Box sx={rowBottom}>
          <Box>
            <Typography
              sx={(theme) => ({
                fontSize: "10px",
                fontWeight: 400,
                lineHeight: "10px",
                color: theme.palette.text.lightGray,
              })}
            >
              Buying
            </Typography>
          </Box>
          <Box>
            <PriceUsd price={usdRate ? valueInput * usdRate : 0} />
          </Box>
        </Box>
      </Grid>
    </>
  );
});

const row = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "5px",
};

const rowBottom = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "10px",
};

const tokenSx = {
  // backgroundColor: "#ff0000",
  // width: "40px",
};

const inputSx = {
  marginRight: "-10px",
};

const InputStyled = styled(Input)(({ theme }) => ({
  textAlign: "right",
  fontWeight: 600,
  fontSize: 14,
  color: "#fff",
  "&.active": {
    fontWeight: 600,
  },
  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "input[type=number]": {
    MozAppearance: "textfield",
  },
}));

const fieldSx = (theme: any) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "0px 10px 0px 10px",
  borderRadius: "6.25px",
  background: "none",
  border: "1px solid #3B474F",
  backgroundColor: "transparent",
  cursor: "pointer",
});

export default ReceiveField;
