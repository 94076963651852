import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useTheme, Theme } from "@mui/material/styles";

import SendField from "./SendField";
import ReceiveField from "./ReceiveField";
import SwitchIcon from "./SwitchIcon";
import SendButton from "./SendButton";
import SwapLoading from "./SwapLoading";

interface SwapPageProps {}

const SwapPage: React.FC<SwapPageProps> = () => {
  const theme = useTheme();

  return (
    <>
      <Container sx={{ padding: 12, maxWidth: "100%" }}>
        <Box sx={borderSx(theme)}>
          <Grid container mt={0}>
            <SwapLoading />
            <Grid container>
              <SendField />
            </Grid>
            <Grid container>
              <SwitchIcon />
            </Grid>
            <Grid container>
              <ReceiveField />
            </Grid>
            <Grid container justifyContent={"center"}>
              <SendButton />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

const borderSx = (theme: Theme) => ({
  position: "relative" as const,
  width: "100%",
  paddingBottom: "16px",
  fontFamily: `"Monda", "Helvetica", "Arial", "Monda", sans-serif`,
  fontWeight: "bold",
  background: "none",
});

export default SwapPage;
