import { useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import { ProjectsButtons } from "@onxrp/ui-common";

import { useAppStore } from "@/stores";

import CoinPairs from "./CoinPairs";
import CoinPrices from "./CoinPrices";
import CoinTabs from "./CoinTabs";
import CoinTabContents from "./CoinTabContents";
import Chart from "./Chart";

interface DexPageProps {}

const DexPage: React.FC<DexPageProps> = () => {
  const {
    dexStore: { setDefaultPair },
  } = useAppStore();

  useEffect(() => {
    const base = new URLSearchParams(window.location.search).get("base");
    const baseIssuer = new URLSearchParams(window.location.search).get(
      "baseissuer"
    );
    // const project = new URLSearchParams(window.location.search).get("project");
    const quote = new URLSearchParams(window.location.search).get("quote");

    // const baseCoinName = base || project || "OXP";
    const baseCoinName = base || "OXP";
    const baseCoinIssuer = baseIssuer || "rrno7Nj4RkFJLzC4nRaZiLF5aHwcTVon3d";
    const quoteCoinName = quote || "XRP";

    setDefaultPair(baseCoinName, baseCoinIssuer, quoteCoinName);
  });

  return (
    <Container className="page">
      <Box display="flex">
        {/* <ProjectsButtons activeLink="dex" theme={theme} /> */}
      </Box>
      <Box sx={{ mb: { xs: 1, md: 4 } }}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <Grid
              sx={{ mb: { xs: "20px", sm: 2 }, mx: { xs: "5px", sm: "0px" } }}
            >
              <CoinPairs />
            </Grid>
            <Grid sx={{ mb: { xs: "20px", sm: 2 } }}>
              <CoinTabs />
            </Grid>
            <Grid>
              <CoinTabContents />
            </Grid>
          </Grid>
          <Grid item md={8} xs={12}>
            <Grid
              sx={{ mb: { xs: "20px", sm: 2 }, mx: { xs: "5px", sm: "0px" } }}
            >
              <CoinPrices />
            </Grid>
            <Grid
              sx={{ mb: { xs: "20px", sm: 2 }, mx: { xs: "5px", sm: "0px" } }}
            >
              <Chart />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default DexPage;
