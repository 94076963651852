import React from "react";
import { observer } from "mobx-react";

import { useAppStore } from "@/stores";
import { ICoinData } from "@/types/IOnRamp";
import Select from "@/components/common/select/Select";
import SelectOption from "@/components/common/select/SelectOption";
import SelectOptions from "@/components/common/select/SelectOptions";

interface SelectCryptoProps {}

const SelectCrypto: React.FC<SelectCryptoProps> = observer(() => {
  const {
    onRampStore: { coins, coinSelected, setCoin },
  } = useAppStore();

  const onSelect = (item: ICoinData) => {
    setCoin(item);
  };

  return (
    <>
      <Select value={<SelectOption item={coinSelected} title={coinSelected?.key} />}>
        <SelectOptions
          items={coins}
          handleSelect={onSelect}
          headerTitle={"Select crypto currency"}
          optionComponent={(item) => <SelectOption item={item} title={item?.key + " " + item?.name} />}
        />
      </Select>
    </>
  );
});

export default SelectCrypto;
