import React from "react";
import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { useTheme } from "@mui/material/styles";
import { useAppStore } from "@/stores";
import { TradeOrderBuySellEnum } from "@/stores/data/dexData";

// Import SVGs
import ActiveBuyTradeTab from "@/assets/images/icons/ActiveBuyTradeTab.svg";
import ActiveSellTradeTab from "@/assets/images/icons/ActiveSellTradeTab.svg";
import InactiveTradeTab from "@/assets/images/icons/InactiveTradeTab.svg";

interface TradeOrderModeTabsProps {}

const TradeOrderModeTabs: React.FC<TradeOrderModeTabsProps> = observer(() => {
  const theme = useTheme();
  const {
    dexStore: {
      setTradeOrderMode,
      tradeOrderBuySellMode,
      setTradeOrderBuySellMode,
      buyOffers,
      sellOffers,
      setTradeOrderPricePerToken,
    },
  } = useAppStore();

  const _changeMode = (e: any) => {
    const mode = e?.target?.value;
    if (!mode) return;

    setTradeOrderMode(Number(1)); // Set the mode to 1 (limit)
    setTradeOrderBuySellMode(Number(mode));

    // Determine the price based on the current trade mode
    if (
      Number(mode) === TradeOrderBuySellEnum.buy &&
      buyOffers.length > 0 &&
      sellOffers[0]
    ) {
      setTradeOrderPricePerToken(sellOffers[0]?.price);
    }

    if (
      Number(mode) === TradeOrderBuySellEnum.sell &&
      sellOffers.length > 0 &&
      buyOffers[0]
    ) {
      setTradeOrderPricePerToken(buyOffers[0]?.price);
    }
  };

  return (
    <>
      <Box
        marginTop={2}
        display="flex"
        justifyContent="center"
        sx={{ position: "relative" }}
      >
        <ToggleButtonGroup
          value={tradeOrderBuySellMode}
          exclusive
          onChange={_changeMode}
          className="market-mode"
          sx={{
            borderRadius: "4px",
            padding: { xs: "0px", sm: "0px" },
            width: "100%",
            background: theme.palette.background.default,
          }}
        >
          <ToggleButton
            value={TradeOrderBuySellEnum.buy}
            sx={{
              padding: "5px",
              borderTopLeftRadius: "3px",
              borderBottomLeftRadius: "3px",
              width: "100%",
              backgroundImage:
                tradeOrderBuySellMode === TradeOrderBuySellEnum.buy
                  ? `url(${ActiveBuyTradeTab})`
                  : `url(${InactiveTradeTab})`,
              backgroundSize: "40px 2px", // Set SVG size
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center bottom 0px", // Move SVG down
              "&::after": {
                content: '""',
                position: "absolute",
                zIndex: -1,
                height: "2px",
                width: "100%",
                left: 0,
                bottom: 0,
                backgroundColor:
                  tradeOrderBuySellMode === TradeOrderBuySellEnum.buy
                    ? theme.palette.text.green
                    : "transparent",
              },
            }}
          >
            {/* You can still keep the text if needed */}
            BUY
          </ToggleButton>
          <ToggleButton
            value={TradeOrderBuySellEnum.sell}
            sx={{
              padding: "5px",
              borderTopRightRadius: "3px",
              borderBottomRightRadius: "3px",
              width: "100%",
              backgroundImage:
                tradeOrderBuySellMode === TradeOrderBuySellEnum.sell
                  ? `url(${ActiveSellTradeTab})`
                  : `url(${InactiveTradeTab})`,
              backgroundSize: "40px 2px", // Set SVG size
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center bottom 0px", // Move SVG down
              "&::after": {
                content: '""',
                position: "absolute",
                zIndex: -1,
                height: "2px",
                width: "100%",
                left: 0,
                bottom: 0,
                backgroundColor:
                  tradeOrderBuySellMode === TradeOrderBuySellEnum.sell
                    ? theme.palette.text.red
                    : "transparent",
              },
            }}
          >
            {/* You can still keep the text if needed */}
            SELL
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </>
  );
});

export default TradeOrderModeTabs;
