import React, { useMemo } from "react";
import { observer } from "mobx-react";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useAppStore } from "@/stores";
import { Link, Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system"

interface PriceRateProps {}

const PriceRate: React.FC<PriceRateProps> = observer(() => {
  const {
    swapStore: {
      priceRate,
      receiveAmount,
      sendAmount,
      sendAssetBalance,
      sendSwap,
      isSubmitting,
    },
    accountStore: { account, userToken, connectWallet },
  } = useAppStore();

  const [isButtonDisable, error] = useMemo<[boolean, string]>(() => {
    if (!priceRate || !receiveAmount || !sendAmount || !sendAssetBalance) {
      return [true, ""];
    }

    if (sendAmount > sendAssetBalance) {
      return [true, "Not enough balance"];
    }

    return [false, ""];
  }, [sendAmount, sendAssetBalance, priceRate, receiveAmount]);

  const onSendSwap = () => {
    if (!account || !userToken || !priceRate) return;

    sendSwap({
      account,
      userToken,
      mode: "sell",
    });
  };

  return (
    <>
      {account ? (
        <Grid container>
          <Grid item xs={12}>
            {error && (
              <Typography sx={errorSx} mb={1}>
                {error}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              variant="onxrpPrimary"
              onClick={onSendSwap}
              disabled={isButtonDisable}
              sx={{
                py: "10px",
                // marginTop: 3,
                marginBottom: 1,
              }}
              loading={isSubmitting}
            >
              SWAP
            </LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={termsSx} mb={1}>
              By clicking 'SWAP' you agree to the{" "}
              <Link
                sx={{ color: "#00fff0", cursor: "pointer" }}
                href="https://drive.google.com/file/d/1Gtkt_hFYbPPTWHaKyGrMwCVdqQM-24vY/view"
                target="_blank"
                rel="noreferrer"
              >
                Terms and Conditions
              </Link>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <LoadingButton
          variant="onxrpPrimary"
          size="large"
          fullWidth
          // loading={isTradeOrderSubmitting}
          sx={{
            py: "10px",
            // marginTop: 3,
            marginBottom: 1,
          }}
          onClick={connectWallet}
        >
          Connect Wallet
        </LoadingButton>
      )}
    </>
  );
});

const errorSx = (theme: Theme): SystemStyleObject<Theme> => ({
  color: theme.palette.text.red,
});

const termsSx = (): SystemStyleObject<Theme> => ({
  color: "#79858C",
  marginTop: "10px",
  fontSize: "0.7rem",
});

export default PriceRate;
