import React from "react";
import { observer } from "mobx-react";

import { useAppStore } from "@/stores";
import LoadingBox from "@/components/common/loading/LoadingBox";

interface OrderBookLoadingProps {}

const OrderBookLoading: React.FC<OrderBookLoadingProps> = observer(() => {
  const {
    dexStore: { isSellOffersLoading, isBuyOffersLoading },
  } = useAppStore();

  return <LoadingBox isLoading={isSellOffersLoading || isBuyOffersLoading} />;
});

export default OrderBookLoading;
