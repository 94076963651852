import RootStore from "@/stores";
import { ICurrency } from "@/types";
import * as Sentry from "@sentry/react";
import axios from "axios";

export async function getCoinsApi({ account }: { account?: string }): Promise<{
  isError: boolean;
  message: string;
  coins: ICurrency[];
}> {
  try {
    // const startTime = new Date().getTime();
    const res_coins = await axios.get(`/api/coins/get-coins`);
    // const endTime = new Date().getTime();

    // console.log(res_coins.data.coins);
    // console.log("getCoinsApi-time", (endTime - startTime) / 1000);
    if (!res_coins.data) {
      return {
        isError: true,
        message: "Unable to get list of coins.",
        coins: [],
      };
    }

    // Fetching balances from XRPL store
    const rootStore: RootStore = new RootStore();
    const ownedTokens = account
      ? await rootStore.xrplStore.getAltBalances(account)
      : [];

    // Map balances by currency identifier, assuming ticker is unique enough
    const balanceMap = ownedTokens.reduce(
      (acc: { [key: string]: { balance: number; issuer: string } }, token) => {
        acc[token.currency] = { balance: token.balance, issuer: token.issuer };
        return acc;
      },
      {}
    );

    // Merge balance information into coins
    const coinsWithBalances = res_coins.data.coins.map((coin: ICurrency) => {
      const coinBalanceData = balanceMap[coin.currencyHex];

      return {
        ...coin,
        balance:
          coinBalanceData && coinBalanceData.issuer === coin.issuer
            ? coinBalanceData.balance
            : 0,
      };
    });

    // Sort coins by presence of balance, and other criteria
    coinsWithBalances.sort((a: ICurrency, b: ICurrency) => {
      if ((a.balance || 0) > 0 && (b.balance || 0) === 0) return -1;
      if ((a.balance || 0) === 0 && (b.balance || 0) > 0) return 1;
      // Within the coins with balances, prioritize 'oxp'
      if (
        a.currencyHex === "OXP" &&
        a.issuer === "rrno7Nj4RkFJLzC4nRaZiLF5aHwcTVon3d" &&
        b.currencyHex !== "OXP" &&
        b.issuer !== "rrno7Nj4RkFJLzC4nRaZiLF5aHwcTVon3d"
      )
        return -1;
      if (
        b.currencyHex === "OXP" &&
        b.issuer === "rrno7Nj4RkFJLzC4nRaZiLF5aHwcTVon3d" &&
        a.currencyHex !== "OXP" &&
        a.issuer !== "rrno7Nj4RkFJLzC4nRaZiLF5aHwcTVon3d"
      )
        return 1;
      if (a.verified && !b.verified) return -1;
      if (!a.verified && b.verified) return 1;
      // return (Number(b.vol24hxrp) || 0) - (Number(a.vol24hxrp) || 0);
    });

    return { isError: false, message: "", coins: coinsWithBalances };
  } catch (error) {
    Sentry.captureException(error);
    return {
      isError: true,
      message: "Unable to get list of coins.",
      coins: [],
    };
  }
}
