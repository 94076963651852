import React from "react";
import { Box, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";
import BiddsWaiting from "../../../assets/bidds/images/biddsWaiting.gif";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loading = ({ width, height }: { width: string; height: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        zIndex: 9999,
      }}
    >
      <Box
        component="img"
        src={BiddsWaiting}
        alt="Loading..."
        sx={{
          width: width,
          height: height,
          // animation: `${rotate} 2s linear infinite`,
        }}
      />
      {/* <Typography variant="h6" sx={{ mt: 2 }}>
        Loading, please wait...
      </Typography> */}
    </Box>
  );
};

export default Loading;
