import { ActionStrings } from "./ActionTypes";

export default function AppReducer(state: any, action: any) {
  switch (action.type) {
    case ActionStrings.LOAD_ACCOUNT:
      return {
        ...state,
        ...action.payload,
      };
    case ActionStrings.SIGN_OUT_ACCOUNT:
      return {
        ...state,
        account: null,
        quoteBalance: 0,
        baseBalance: 0,
      };
    case ActionStrings.LOAD_QUOTE_COIN_BALANCE:
      return {
        ...state,
        quoteBalance: action.payload.balance,
      };
    case ActionStrings.LOAD_BASE_COIN_BALANCE:
      return {
        ...state,
        baseBalance: action.payload.balance,
        altTrustlineActive: action.payload.trustlineActive,
      };
    case ActionStrings.CHANGE_PAIR_COINS:
      return {
        ...state,
        baseCoin: action.payload.baseCoin,
        quoteCoin: action.payload.quoteCoin
      };
    default:
      return state;
  }
}
