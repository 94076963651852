import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { observer } from "mobx-react";

import TradeTabs from "./TradeTabs";
import TradeOrderModeTabs from "./TradeOrderModeTabs";
import TradeOrderSetPercent from "./TradeOrderSetPercent";
import TradeOrderInputs from "./TradeOrderInputs";
import TradeOrderButtons from "./TradeOrderButtons";
import TradeLoading from "./TradeLoading";

import SendField from "@/components/pages/swap/SendField";
import ReceiveField from "@/components/pages/swap/ReceiveField";
import SwitchIcon from "@/components/pages/swap/SwitchIcon";
import SendButton from "@/components/pages/swap/SendButton";
import SwapLoading from "@/components/pages/swap/SwapLoading";

import { useAppStore } from "@/stores";
import { TradeModeEnum } from "@/stores/data/dexData";
import TradeOrderInputTotal from "./TradeOrderInputTotal";
import TradeOrderSlippage from "./TradeOrderSippage";
import { Link, Theme, Typography } from "@mui/material";
import { SystemStyleObject } from "@mui/system"

interface TradeProps {}

const Trade: React.FC<TradeProps> = observer(() => {
  const {
    dexStore: { tradeMode, rateUsdXrp },
  } = useAppStore();

  return (
    <Box
      className="boxCard"
      sx={{
        position: "relative",
        minHeight: { md: "100%" },
      }}
    >
      <Grid container>
        <TradeTabs />
      </Grid>
      <Grid
        container
        sx={{
          padding: {
            xs: 1, // 10px padding for extra-small (mobile) screens
            sm: 2, // default padding for small screens and up
          },
        }}
      >
        <TradeLoading />
        {tradeMode === TradeModeEnum.swap ? (
          <SwapUI rateUsdXrp={rateUsdXrp} />
        ) : (
          <LimitUI />
        )}
      </Grid>
    </Box>
  );
});

const SwapUI: React.FC<{ rateUsdXrp: number | null }> = observer(
  ({ rateUsdXrp }) => {
    const {
      swapStore: { isRateLoading, isInitLoading },
      dexStore: {
        isOpenOrdersLoading,
        isHistoryOrdersLoading,
        isSellOffersLoading,
        isBuyOffersLoading,
      },
    } = useAppStore();

    return (
      <>
        {/* <SwapLoading /> */}
        {isRateLoading &&
          !isInitLoading &&
          !isOpenOrdersLoading &&
          !isHistoryOrdersLoading &&
          !isSellOffersLoading &&
          !isBuyOffersLoading && <SwapLoading />}
        <Grid item xs={12} mb={2}>
          <TradeOrderSlippage />
        </Grid>
        <Grid item xs={12} mb={2}>
          <TradeOrderSetPercent />
        </Grid>
        <Grid item xs={12}>
          <SendField usdRate={rateUsdXrp} />
        </Grid>
        <Grid item xs={12} mb={1} justifyContent="center">
          <SwitchIcon />
        </Grid>
        <Grid item xs={12} mb={2}>
          <ReceiveField usdRate={rateUsdXrp} />
        </Grid>
        <Grid item xs={12} justifyContent={"center"}>
          <SendButton />
        </Grid>
      </>
    );
  }
);

const LimitUI: React.FC = () => {
  return (
    <>
      {/* <Grid item xs={12} pl={0}>
        <AvailableToTrade />
      </Grid> */}
      <Grid item xs={12} mb={2}>
        <TradeOrderModeTabs />
      </Grid>
      <Grid item xs={12} mb={2}>
        <TradeOrderSetPercent />
      </Grid>
      <Grid item xs={12} mb={2}>
        <TradeOrderInputs />
      </Grid>
      <Grid item xs={12}>
        <TradeOrderInputTotal />
      </Grid>
      <Grid item xs={12}>
        <TradeOrderButtons />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={termsSx} mb={1}>
          By clicking 'BUY' you agree to the{" "}
          <Link
            sx={{ color: "#00fff0", cursor: "pointer" }}
            href="https://drive.google.com/file/d/1Gtkt_hFYbPPTWHaKyGrMwCVdqQM-24vY/view"
            target="_blank"
            rel="noreferrer"
          >
            Terms and Conditions
          </Link>
        </Typography>
      </Grid>
    </>
  );
};

const borderSx: SystemStyleObject<Theme> = {
  position: "relative" as const,
  width: "100%",
  paddingBottom: "16px",
  fontFamily: `"Monda", "Helvetica", "Arial", "Monda", sans-serif`,
  fontWeight: "bold",
  background: "none",
};

const termsSx = (): SystemStyleObject<Theme> => ({
  color: "#79858C",
  marginTop: "10px",
  fontSize: "0.7rem",
});

export default Trade;
