import React, { useState } from "react";
import { Box, Modal, Backdrop, Fade, useTheme, Theme } from "@mui/material";

import { ReactComponent as ArrowDownIcon } from "./assets/images/arrowDown.svg";
import { ReactComponent as ArrowDownDarkIcon } from "./assets/images/arrowDownDark.svg";
import { ReactComponent as ArrowDownHoverIcon } from "./assets/images/arrowDownHover.svg";
import { ReactComponent as ArrowDownClickIcon } from "./assets/images/arrowDownClick.svg";

const modalBodyStyle = (theme: any) => ({
  position: "absolute" as "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", lg: "calc(100% - 40px)" },
  minHeight: "40vh",
  maxWidth: "500px",
  bgcolor: "rbga(0, 0, 0, 0.4)",
  borderRadius: "6.25px",
  overflow: "hidden",
  padding: {
    xs: "0px",
    sm: "32px 32px px",
  },
  [theme.breakpoints.down("sm")]: {
    top: "50%",
    height: "100%",
    maxWidth: "100%",
  },
});

const inputStyle = (theme: any) => ({
  // position: "relative",
  display: "flex",
  height: { xs: "30px", sm: "30px" },
  width: "100%",
  minWidth: "50px",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "8px",
  padding: "6px, 10px, 6px, 10px",
  borderRadius: "6.25px",
  border: "transparent",
  borderColor: "transparent",
  cursor: "pointer",
  background: "#25333C",
  // "& span": {
  //   position: "absolute",
  //   display: "flex",
  //   height: "100%",
  //   top: 0,
  //   right: { xs: "12px", md: "16px" },
  //   alignItems: "center",
  // },
});

type valueType = string | number | React.ReactNode | null;
interface SelectProps {
  value?: valueType;
  children?: React.ReactChild | React.ReactChild[];
  onSelect?: (selectedItem: any) => void;
}

const Select: React.FC<SelectProps> = ({ value, children }) => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const [iconState, setIconState] = useState<string>("default");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleMouseEnter = () => setIconState("hover");
  const handleMouseLeave = () => setIconState("default");
  const handleMouseDown = () => setIconState("click");
  const handleMouseUp = () => setIconState("hover");

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, {
        handleClose,
      });
    }

    return child;
  });

  const renderIcon = () => {
    if (iconState === "hover") {
      return theme.palette.mode !== "dark" ? (
        <ArrowDownHoverIcon />
      ) : (
        <ArrowDownDarkIcon />
      );
    } else if (iconState === "click") {
      return theme.palette.mode !== "dark" ? (
        <ArrowDownClickIcon />
      ) : (
        <ArrowDownDarkIcon />
      );
    } else {
      return theme.palette.mode !== "dark" ? (
        <ArrowDownIcon />
      ) : (
        <ArrowDownDarkIcon />
      );
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", py: "5px" }} onClick={handleOpen}>
        <Box
          sx={inputStyle}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          <Box sx={tokenSx}>{value}</Box>
          <Box sx={arrowSx}>{renderIcon()}</Box>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal-body"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: {
            opacity: "0.9",
          },
          sx: {
            backgroundColor: "background.backDrop",
          },
        }}
        disableAutoFocus
      >
        <Fade in={open}>
          <Box sx={modalBodyStyle}>{childrenWithProps}</Box>
        </Fade>
      </Modal>
    </>
  );
};

const tokenSx = (theme: Theme) => ({
  color: theme.palette.text.green,
  ml: "8px",
});

const arrowSx = (theme: Theme) => ({
  mt: "-6px",
  mr: "16px",
  color: theme.palette.text.lightGray,
});

export default Select;
