import React from "react";
import { observer } from "mobx-react";

import { useAppStore } from "@/stores";
import LoadingBox from "@/components/common/loading/LoadingBox";

interface ChartLoadingProps {}

const ChartLoading: React.FC<ChartLoadingProps> = observer(() => {
  const {
    dexStore: { isChartLoading, exchanges, isExchangesDataError },
  } = useAppStore();

  return (
    <LoadingBox
      isLoading={isChartLoading}
      title={
        isExchangesDataError && (!exchanges || exchanges.length === 0)
          ? "Historical data is not available"
          : undefined
      }
    />
  );
});

export default ChartLoading;
