import React, { useEffect } from "react";
import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

import { useAppStore } from "@/stores";
import LoadingBox from "@/components/common/loading/LoadingBox";
import { Theme, useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";

interface OrdersOpenProps {}

const OrdersOpen: React.FC<OrdersOpenProps> = observer(() => {
  const theme = useTheme();

  const {
    dexStore: {
      baseCoin,
      quoteCoin,
      openOrders,
      isOpenOrdersLoading,
      getAccountOffers,
      cancelOrder,
    },
    accountStore: { account, connectWallet },
  } = useAppStore();

  useEffect(() => {
    getAccountOffers();
  }, [getAccountOffers, baseCoin]);

  if (!account) {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        py={2}
        px="8px"
        width="100%"
      >
        <LoadingButton
          variant="onxrpPrimary"
          size="large"
          fullWidth
          // loading={isTradeOrderSubmitting}
          sx={{
            py: "10px",
            // marginTop: 3,
            marginBottom: 1,
          }}
          onClick={connectWallet}
        >
          Connect Wallet
        </LoadingButton>
      </Box>
    );
  }

  if (!isOpenOrdersLoading && openOrders.length <= 0) {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        py={2}
        px={4}
        width="100%"
      >
        <Typography sx={{ textAlign: "center", width: "100%" }}>
          You don't have any open orders.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <LoadingBox isLoading={isOpenOrdersLoading} />
      {openOrders.map((l: any, index: number) => (
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          borderBottom="1px solid #25333C"
          py={2}
          px={3}
          key={index}
        >
          <Box textAlign="left">
            <Typography sx={textOperationSx(theme, l.mode)} mb={1}>
              {l.mode}
            </Typography>
            <Typography color="gray.light" fontSize="0.7rem"></Typography>
          </Box>
          <Box textAlign="right">
            <Box display="flex" alignItems="center">
              <Box>
                <Typography sx={textOperationSx(theme, l.mode)} mb={1}>
                  {l.volume} {baseCoin?.ticker}
                </Typography>
                <Typography color="gray.main" fontSize="0.7rem">
                  {l.total} {quoteCoin?.ticker}
                </Typography>
              </Box>
              <IconButton onClick={() => cancelOrder(l)} color="error">
                <CancelIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
});

const textOperationSx = (theme: Theme, oper: string | undefined) => ({
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "12px",
  color:
    oper === "BUY"
      ? theme.palette.text.positiveFigure
      : theme.palette.text.negativeFigure,
});

export default OrdersOpen;
