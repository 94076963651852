import React from "react";
import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useAppStore } from "../../../stores";
import TransactionVertical from "../../../assets/images/icons/transactionVertical.svg";
import { Divider, IconButton } from "@mui/material";

interface SwitchIconProps {}

const SwitchIcon: React.FC<SwitchIconProps> = observer(() => {
  const theme = useTheme();
  const {
    swapStore: { switchAssets },
  } = useAppStore();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Divider sx={{ flexGrow: 1 }} />
        <IconButton onClick={switchAssets} sx={switchSx}>
          <img src={TransactionVertical} alt="Trade" />
        </IconButton>
        <Divider sx={{ flexGrow: 1 }} />
      </Box>
    </>
  );
});

const switchSx = {
  mx: 2, // Margin on the sides of the button

  cursor: "pointer",
  padding: { xs: "8px", sm: "12px" }, // Add padding for the arrows
  display: "flex",
  justifyContent: "center",
  alignItems: "center", // Corrected from "alignItem" to "alignItems"
  borderRadius: { xs: "4px", sm: "8px" },
  background: "#143539",
  transition: "background-color 230ms cubic-bezier(0.4, 0, 0.2, 1)", // Initial transition for background change
  "&:hover": {
    backgroundColor: "#1A464B", // Hover effect
  },
  "&:active": {
    backgroundColor: "#0E2527", // Active effect
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1)", // Faster transition on active
  },
  svg: {
    width: { xs: "12px", sm: "16px" },
    height: { xs: "12px", sm: "16px" },
  },
};

export default SwitchIcon;
