import { makeAutoObservable, runInAction } from "mobx";
import { ReactSession } from "react-client-session";
import { TransactionStream } from "xrpl";

import RootStore from "./index";

interface Balances {
  balance: number;
  currency: string;
}

export default class AccountStore {
  public rootStore: RootStore;
  account?: string;
  userToken?: string;
  balances: Balances[] = [];
  isBalancesFetching: boolean = false;
  quoteBalance: number = 0;
  isQuoteCoinTrustlineActive: boolean = false;
  baseBalance: number = 0;
  isBaseCoinTrustlineActive: boolean = false;

  trustlineActive: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
    ReactSession.setStoreType("localStorage");
    this.loadAccount();
  }

  loadAccount() {
    this.account = ReactSession.get("xpunk-account");
    this.userToken =
      ReactSession.get("xpunk-userToken") ??
      ReactSession.get("xpunk-xummObj")?.application?.issued_user_token;
    this.accountSubscribe();
    this.getBalances();
  }

  signOutAccount() {
    ReactSession.remove("xpunk-account");
    ReactSession.remove("xpunk-userToken");
    ReactSession.remove("xpunk-xummObj");

    this.account = undefined;
  }

  connectWallet() {
    this.rootStore.walletStore.signIn();
    this.getBalances();
  }

  disconnectWallet() {
    this.account = undefined;
    this.quoteBalance = 0;
    this.baseBalance = 0;
    this.signOutAccount();
  }

  async getBalances() {
    if (!this.account || this.isBalancesFetching) return;

    this.isBalancesFetching = true;

    const balancesReq = await this.rootStore.xrplStore.getAltBalances(
      this.account
    );
    const xrpBalancesReq = await this.rootStore.xrplStore.getXrpBalance(
      this.account
    );

    const [balances, xrpBalances] = await Promise.all([
      balancesReq,
      xrpBalancesReq,
    ]);

    runInAction(() => {
      this.balances = [xrpBalances, ...balances];
      this.isBalancesFetching = false;
      this.updateCoinsBalance();
      this.rootStore.swapStore.updateBalances();
      this.rootStore.dexStore.isBalancesLoading = false;
    });
  }

  updateCoinsBalance() {
    this.setBaseBalance();
    this.setQuoteBalance();
  }

  setQuoteBalance() {
    const quoteCoin = this.rootStore.dexStore?.quoteCoin;

    if (!quoteCoin) return;

    const quoteCoinLine = this.balances.find(
      (line) => line.currency === quoteCoin.currencyHex
    );
    const quoteCoinBalance = quoteCoinLine?.balance
      ? Number(quoteCoinLine?.balance)
      : 0;
    const isQuoteCoinTrustlineActive = quoteCoinLine ? true : false;

    runInAction(() => {
      this.quoteBalance = quoteCoinBalance;
      this.isQuoteCoinTrustlineActive = isQuoteCoinTrustlineActive;
    });
  }

  setBaseBalance() {
    const baseCoin = this.rootStore.dexStore?.baseCoin;

    if (!baseCoin) return;
    const baseCoinLine = this.balances.find(
      (line) => line.currency === baseCoin.currencyHex
    );
    const baseCoinBalance = baseCoinLine?.balance
      ? Number(baseCoinLine?.balance)
      : 0;
    const isBaseCoinTrustlineActive = baseCoinLine ? true : false;

    runInAction(() => {
      this.baseBalance = baseCoinBalance;
      this.isBaseCoinTrustlineActive = isBaseCoinTrustlineActive;
    });
  }

  accountSubscribe() {
    if (!this.account) return;

    this.rootStore.xrplStore.accountSubscribe(this.account);
  }

  transactionSubscribeUpdate(tx: TransactionStream) {
    if (tx?.transaction?.Account !== this.rootStore.accountStore.account)
      return;

    this.getBalances();
  }
}
