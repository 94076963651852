import React from "react";
import { observer } from "mobx-react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

import { useAppStore } from "@/stores";
import { PeriodEnum } from "@/stores/data/dexData";
import { PeriodAlter } from "@/types";

interface ChartIntervalButtonsProps {}

const ChartIntervalButtons: React.FC<ChartIntervalButtonsProps> = observer(
  () => {
    const {
      dexStore: { chartInterval, setChartInterval },
    } = useAppStore();

    const _handleChangeInterval = (e: any) => {
      setChartInterval(e.target.value);
    };

    const period = (interval: PeriodAlter) => PeriodEnum[interval] || "1day";

    return (
      <ToggleButtonGroup
        className="chart-period-buttons"
        value={chartInterval}
        onChange={_handleChangeInterval}
        exclusive
        sx={{
          // background: `linear-gradient(90deg, ${theme.palette.button.gradientStart}, ${theme.palette.button.gradientEnd})`,
          borderRadius: "0",
          borderBottomRightRadius: "4px",
          borderBottomLeftRadius: "4px",
          padding: { xs: "0px", sm: "1px" },
          paddingBottom: { xs: "1px", sm: "1px" },
          overflow: "hidden",
          position: "relative",
          top: "-1px",
          zIndex: 1,
        }}
      >
        <ToggleButton
          value={period("1m")}
          sx={{
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "3px",
          }}
        >
          1M
        </ToggleButton>
        <ToggleButton value={period("5m")}>5M</ToggleButton>
        <ToggleButton value={period("15m")}>15M</ToggleButton>
        <ToggleButton value={period("30m")}>30M</ToggleButton>
        <ToggleButton value={period("1h")}>1H</ToggleButton>
        <ToggleButton value={period("3h")}>3H</ToggleButton>
        <ToggleButton value={period("6h")}>6H</ToggleButton>
        <ToggleButton value={period("12h")}>12H</ToggleButton>
        <ToggleButton value={period("1d")}>1D</ToggleButton>
        <ToggleButton value={period("3d")}>3D</ToggleButton>
        <ToggleButton
          value={period("1w")}
          sx={{
            borderTopRightRadius: "0",
            borderBottomRightRadius: "3px",
          }}
        >
          7D
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }
);

export default ChartIntervalButtons;
