import React from "react";
import { observer } from "mobx-react";
import { Typography } from "@mui/material";
import { formatNumber } from "@/helpers/formatter";

interface TradeTabsProps {
  price: number | string | undefined;
  fontSize?: string;
}

const PriceUsd: React.FC<TradeTabsProps> = observer(({ price, fontSize }) => {
  // const [_price, _setPrice] = useState(price);

  // useEffect(() => {
  //   if (!price) _setPrice(0);
  // }, [price]);

  return (
    <>
      <Typography
        sx={(theme) => ({
          fontSize: `${fontSize || 10}px`,
          fontWeight: 400,
          lineHeight: `${fontSize || 10}px`,
          color: theme.palette.text.lightGray,
          ml: "8px",
        })}
      >
        {!price ? `(--)` : `($${formatNumber(price, 2, 2, false, true)})`}
      </Typography>
    </>
  );
});

export default PriceUsd;
