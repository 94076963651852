import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Theme,
  Divider,
  TextField,
} from "@mui/material";

import VerificationIcon from "../../../assets/images/icons/verification.svg";

import close from "@/assets/images/closeIcon.svg";
import { useAppStore } from "@/stores";

type OptionsItem = {
  key: string;
  name: string;
  symbol?: string;
  img?: string;
  disable?: boolean;
  hide?: boolean;
  [x: string]: any;
};

interface SelectOptionsProps {
  optionComponent?: (item: OptionsItem) => React.ReactChild;
  items?: OptionsItem[];
  handleSelect: (item: OptionsItem) => void;
  handleClose?: () => void;
  headerTitle?: string;
  placeholder?: string;
}

const SelectOptions: React.FC<SelectOptionsProps> = ({
  handleSelect,
  handleClose,
  items,
  headerTitle = "Select",
  placeholder = "Type Token Ticker",
  optionComponent,
}) => {
  const [phrase, setPhrase] = useState<string>("");
  const {
    swapStore: { assets },
  } = useAppStore();

  const [coinMain, setCoinMain] = useState<string>("XRP");

  const itemsFiltered = useMemo(() => {
    return phrase.length > 0
      ? items
          ?.filter(
            (item) =>
              item?.name
                ?.toString()
                .toLowerCase()
                .includes(phrase.toLowerCase()) ||
              item?.key?.toString().toLowerCase().includes(phrase.toLowerCase())
          )
          .slice(0, 100)
      : items?.slice(0, 100);
  }, [phrase, assets]);

  const onSelectOption = (item: OptionsItem) => {
    if (item.disable) return;

    handleSelect(item);

    if (typeof handleClose === "function") {
      handleClose();
    }
  };

  const onFilter = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const searchPhrase = e.target?.value;
    setPhrase(searchPhrase);
  };

  return (
    <Box sx={selectWrapperSx}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="start"
        pt={2}
        // pb={{ xs: 2, sm: 3 }}
      >
        <Grid>
          <Typography sx={titleSx} mb={2}>
            {headerTitle}
          </Typography>
          <Typography sx={textSx} mb={2}>
            Please select a token you wish to swap on the <span>bidds</span>{" "}
            DEX.
          </Typography>
          <Typography sx={subtitleSx}>Available Pairs</Typography>
        </Grid>
        <Box
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            color: "#ffffff",
            height: "28px",
            width: "28px",
            backgroundColor: "#000",
            borderRadius: "8px",
            border: "1px solid #25333C",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <img className="close-icon" src={close} alt="close" />
        </Box>
      </Grid>
      <Grid mb={1}>
        <TextField
          fullWidth
          placeholder={placeholder}
          onChange={onFilter}
          className="pairs-search"
          sx={inputSx}
        />
        <Divider></Divider>
      </Grid>

      <Box sx={BoxSx}>
        {itemsFiltered &&
          itemsFiltered.map((item, index) => (
            <Box
              key={item.currencyHex + item.issuer}
              sx={itemSx}
              onClick={() => onSelectOption(item)}
            >
              <Box sx={pairSx}>
                <Typography sx={basecoindSx}>
                  {item.name} <Box component={"span"} sx={quiteCoinSx}></Box>
                </Typography>
                {item?.name_long && (
                  <Typography sx={nameTokenSx}>({item?.name_long})</Typography>
                )}
                <Box
                  component={"span"}
                  sx={{ marginLeft: "8px", height: "16px" }}
                >
                  {item?.verified && (
                    <img src={VerificationIcon} alt="success" />
                  )}
                </Box>

                <Typography sx={basecoindSx}>
                  {item?.isMeme && <Typography>FL</Typography>}
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

const BoxSx = {
  overflowY: "auto",
  maxHeight: { xs: "100vh", sm: "30vh" },
  // height: "auto",
};

const listSx = (theme: Theme) => ({
  overflowY: "auto",
  height: "100%",
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main,
    borderRadius: "2px",
  },
  // [theme.breakpoints.down("sm")]: {
  //   maxHeight: "100%",
  // },
});

const selectWrapperSx = (theme: Theme) => ({
  padding: "0px 16px 0px 16px",
  background: "#161F25",
  // padding: "10px",
  borderRadius: { sm: "0px", md: "15px" },
  width: "100%",
  height: "100%",
  h4: {
    fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
  },
});

const inputSx = {
  color: "#ffffff",
  borderRadius: "6.25px",
  border: "1px solid #25333C",
  marginTop: "16px",
  marginBottom: "16px",
  "& .MuiInputBase-root": {
    padding: "0px 0px",
  },
};

const titleSx = (theme: Theme) => ({
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "28px",
});

const subtitleSx = (theme: Theme) => ({
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "18px",
});

const textSx = (theme: Theme) => ({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "18px",
  "& span": {
    color: theme.palette.text.bidds,
  },
});

const itemSx = {
  margin: "8px 8px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  alignItems: "center",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    borderRadius: "4px !important",
    backgroundColor: "#1D282F!important",
    transition: "all 0.3s ease-in-out",
    "& svg": {
      opacity: 1,
    },
  },
};

const basecoindSx = (theme: Theme) => ({
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "12px",
  color: theme.palette.text.secondary,
});

const nameTokenSx = (theme: Theme) => ({
  marginLeft: "10px",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "10px",
  color: theme.palette.text.grayBorder,
});

const pairSx = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
};

const quiteCoinSx = (theme: Theme) => ({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "13px",
  color: theme.palette.text.grayBorder,
});

export default SelectOptions;
