import React from "react";
import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import { useAppStore } from "@/stores";
import {
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { head } from "lodash";

interface TradeOrderSlippageProps {}

const TradeOrderSlippage: React.FC<TradeOrderSlippageProps> = observer(() => {
  const {
    swapStore: { slippage, setSlippage },
  } = useAppStore();

  const _changeMode = (e: any) => {
    let slippage = Number(e?.target?.value);
    if (Number(slippage) > 100) {
      slippage = 100;
    }

    if (!slippage) setSlippage(0);
    setSlippage(slippage);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignContent="center"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignContent="center"
          justifyContent="space-between"
        >
          <Box alignContent="center">
            <Typography sx={titleSx}>Max Slippage</Typography>
          </Box>
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            placement="right"
            title="Slippage is the difference between the expected price of a trade and the actual price at which it is executed, often occurring during high volatility or low liquidity."
          >
            <IconButton sx={{ marginRight: 1 }}>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box justifyContent="flex-end">
          <TextField
            sx={inputSlippageSx}
            id="swap-base-input"
            name="swap-base"
            placeholder="1.25"
            value={slippage === 0 ? "" : slippage}
            // disableUnderline
            autoComplete="off"
            type="text"
            onClick={(e) => e.stopPropagation()}
            onChange={_changeMode}
            //   disabled={!sendAsset || !receiveAsset}
            inputProps={{
              pattern: "^[0-9]*[.,]?[0-9]*$",
              minLength: "1",
              maxLength: "79",
              inputMode: "decimal",
              autoCorrect: "off",
              spellCheck: "false",
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Box>
      </Box>
    </>
  );
});

const titleSx = (theme: Theme) => ({
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "12px",
  color: theme.palette.text.secondary,
});

const inputSlippageSx = (theme: Theme) => ({
  alignSelf: "left",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "12px",
  border: "1px solid #3B474F",
  borderRadius: "6.25px",
  // color: theme.palette.text.red,
  width: "80px",
  padding: "0px 0px",
  "& .MuiInputBase-root": {
    padding: "0px 0px",
    height: "40px",
  },
});

export default TradeOrderSlippage;
