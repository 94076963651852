import React, { useState } from "react";
import { Box, ToggleButtonGroup, ToggleButton, useTheme } from "@mui/material";

import OrdersClosed from "./OrdersClosed";
import OrdersOpen from "./OrdersOpen";

interface OrderLogsProps {}

const OrderLogs: React.FC<OrderLogsProps> = () => {
  const theme = useTheme();
  const [mode, setMode] = useState("offers");

  const _changeMode = (e: any) => {
    setMode(e.target.value);
  };

  return (
    <Box
      className="boxCard"
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "528px",
        maxHeight: "528px",
      }}
    >
      <ToggleButtonGroup
        fullWidth
        value={mode}
        exclusive
        onChange={_changeMode}
        className="tabs-content"
        sx={{ maxHeight: "48px" }}
      >
        <ToggleButton
          value="offers"
          sx={{
            borderTopLeftRadius: "7px",
            borderTopRightRadius: "7px !important",
            // background: `linear-gradient(90deg, ${theme.palette.button.gradientStart}, ${theme.palette.button.gradientMiddle})`,
            color: theme.palette.text.secondary,
            "&.Mui-selected": {
              background: theme.palette.background.main,
              borderRight: `1px solid ${theme.palette.border.main}`,
            },
          }}
        >
          OPEN ORDERS
        </ToggleButton>
        <ToggleButton
          value="history"
          sx={{
            borderTopLeftRadius: "7px !important",
            borderTopRightRadius: "7px",
            // background: `linear-gradient(90deg, ${theme.palette.button.gradientMiddle}, ${theme.palette.button.gradientEnd})`,
            color: theme.palette.text.secondary,
            borderLeftColor: `${theme.palette.button.gradientMiddle}!important`,
            "&.Mui-selected": {
              background: theme.palette.background.main,
              borderLeft: `1px solid ${theme.palette.border.main}!important`,
            },
          }}
        >
          TRADE HISTORY
        </ToggleButton>
      </ToggleButtonGroup>
      <Box
        py={2}
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          maxHeight: "100%",
          position: "relative",
          borderBottomRightRadius: "24px",
          borderBottomLeftRadius: "24px",
          overflowY: "scroll",
        }}
      >
        {mode === "offers" ? <OrdersOpen /> : <OrdersClosed />}
      </Box>
    </Box>
  );
};

export default OrderLogs;
