import React, { createContext, useReducer, Dispatch } from "react";
import * as Sentry from "@sentry/react";
// @ts-ignore
import { ReactSession } from "react-client-session";
import axios from "axios";

import { ICurrency } from "@/types/ICurrency";

import AppReducer from "./AppReducer";
import { ActionStrings } from "./ActionTypes";

// Initial State
const initialState: {
  account?: string;
  userToken?: string;
  quoteBalance: number;
  baseBalance: number;
  baseCoin?: ICurrency;
  quoteCoin?: ICurrency;
  altTrustlineActive: boolean;
} = {
  quoteBalance: 0,
  baseBalance: 0,
  altTrustlineActive: false,
};

// create global context
export const GlobalContext = createContext({
  state: initialState,
  loadAccount: () => {},
  signOutAccount: () => {},
  loadQuoteBalance: () => {},
  loadAltBalance: (_: any) => {},
  dispatch: (() => null) as Dispatch<any>,
});

type GlobalProviderProps = {
  children: React.ReactNode; // 👈️ type children
};

// Context Provider
export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions
  function loadAccount() {
    const account = ReactSession.get("xpunk-account");
    const userToken =
      ReactSession.get("xpunk-userToken") ?? ReactSession.get("xpunk-xummObj")?.application?.issued_user_token;
    dispatch({ type: ActionStrings.LOAD_ACCOUNT, payload: { account, userToken } });
  }

  function signOutAccount() {
    ReactSession.remove("xpunk-account");
    ReactSession.remove("xpunk-userToken");
    ReactSession.remove("xpunk-xummObj");
    dispatch({ type: ActionStrings.SIGN_OUT_ACCOUNT });
  }

  async function loadQuoteBalance() {
    const account = ReactSession.get("xpunk-account");

    try {
      if (account) {
        const result =
          !state.quoteCoin || state.quoteCoin?.ticker === "XRP"
            ? await axios.post("/api/account/balance/xrp", {
                account: account,
              })
            : await axios.post("/api/account/balance/alt", {
                account: account,
                currency: state.quoteCoin.ticker,
                currencyHex: state.quoteCoin.currencyHex,
              });

        dispatch({
          type: ActionStrings.LOAD_QUOTE_COIN_BALANCE,
          payload: { balance: result.data.balance },
        });
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  async function loadAltBalance(coin: ICurrency) {
    const account = ReactSession.get("xpunk-account");

    try {
      if (account) {
        const result = await axios.post("/api/account/balance/alt", {
          account: account,
          currency: coin.ticker,
          currencyHex: coin.currencyHex,
        });

        dispatch({
          type: ActionStrings.LOAD_BASE_COIN_BALANCE,
          payload: {
            balance: result.data.balance,
            trustlineActive: result.data.trustlineActive,
          },
        });
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
        loadAccount,
        signOutAccount,
        loadQuoteBalance,
        loadAltBalance,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
