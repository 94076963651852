import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material/styles";

interface SelectOptionProps {
  item?: {
    key: string;
    name: string;
    symbol?: string;
    img?: string;
  };
  title?: React.ReactNode | string;
  sx?: SxProps;
}

const SelectOption: React.FC<SelectOptionProps> = ({ item, title }) => {
  return (
    <>
      {item && (
        <Grid container alignItems="left" className="select-option">
          <Grid xs item alignSelf={"left"}>
            <Typography fontSize={{ xs: "12px", sm: "12px" }}>
              {title || item.name}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SelectOption;
