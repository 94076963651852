import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { ReactComponent as WalletIcon } from "@/assets/images/wallet.svg";
import { useAppStore } from "@/stores";
import { Box, Button } from "@mui/material";

interface WaitWalletProps {}

const WaitWallet: React.FC<WaitWalletProps> = () => {
  const {
    walletStore: { deepLink, qrImgUrl },
  } = useAppStore();

  return (
    <Grid container justifyContent="space-between" height="auto">
      <Grid item xs={12}>
        <Grid item xs={12} textAlign="left" mt={1} mb={7}>
          <Typography fontSize="12px" fontWeight="400" lineHeight="18px">
            Open your Xaman Mobile App to sign the transaction.
          </Typography>
        </Grid>

        <Grid item xs={12} mb={4}>
          <Grid container justifyContent="center">
            {qrImgUrl && (
              <img
                src={qrImgUrl}
                alt="Scan QR Code"
                style={{ maxWidth: "250px", width: "100%" }}
              />
            )}
          </Grid>
        </Grid>
        <Box textAlign="center">
          <Grid item xs={12} mb={1}>
            <Typography fontSize="12px" fontWeight="400" lineHeight="18px">
              Waiting for extension...
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize="12px" fontWeight="400" lineHeight="18px">
              <strong>Status:</strong>connecting...
            </Typography>
          </Grid>
        </Box>
      </Grid>

      {deepLink && (
        <div
          // item
          // xs={12}
          // justifyContent="end"
          // position="absolute"
          // left="0px"
          // right="0px"
          // bottom="40px"
          // margin="16px"
          className="top-nav__mobile-menu--wrapper"
        >
          <Grid
            // container
            sx={{
              display: { md: "none", xs: "block" },
              // justifyContent: "center",
              // marginBottom: "16px",
            }}
          >
            <Button
              href={deepLink}
              title="Open in Xamman"
              target="_blank"
              fullWidth
              variant="onxrpPrimary"
              // className="gradient-button"
              color="primary"
              sx={{
                p: 1.5,
                borderRadius: "6.25px",
                textDecoration: "none",
              }}
            >
              Open in XAMMAN
            </Button>
          </Grid>
        </div>
      )}
    </Grid>
  );
};

export default WaitWallet;
