import Collections from "../assets/bidds/images/collections.svg";
import Home from "../assets/bidds/images/home.svg";
import Launchpad from "../assets/bidds/images/launchpad.svg";
import Profile from "../assets/bidds/images/profile.svg";
import Chart from "../assets/bidds/images/chart.svg";
// import InfoActive from "../../../assets/bidds/images/info-active.svg";
// import InfoInactive from "../../../assets/bidds/images/info-inactive.svg";

export const PAGE_LINKS = {
  HOME: "/",
  COLLECTIONS: "/collections",
  LAUNCHPAD: "/launchpad",
  DOMAIN: "/domain",
  DEX: "/",
};

export const MAIN_MENU_ITEMS = [
  {
    title: "Home",
    iconActive: Home,
    iconInactive: Home,
    isActive: true,
    isCurrentPage: false,
    link: PAGE_LINKS.HOME,
    isSubMenu: false,
  },
  // {
  //   title: "My Profile",
  //   iconActive: Profile,
  //   iconInactive: Profile,
  //   isActive: true,
  //   isCurrentPage: true,
  //   link: PAGE_LINKS.PROFILE_SLUG,
  //   isSubMenu: true,
  //   key: "profile",
  // },
  {
    title: "Collections",
    iconActive: Collections,
    iconInactive: Collections,
    isActive: true,
    isCurrentPage: false,
    link: PAGE_LINKS.COLLECTIONS,
    isSubMenu: false,
  },
  {
    title: "Launchpad",
    iconActive: Launchpad,
    iconInactive: Launchpad,
    isActive: true,
    isCurrentPage: false,
    link: PAGE_LINKS.LAUNCHPAD,
    isSubMenu: false,
  },
  {
    title: "Domains",
    iconActive: Profile,
    iconInactive: Profile,
    isActive: true,
    isCurrentPage: false,
    link: PAGE_LINKS.DOMAIN,
    isSubMenu: false,
    chains: ["coreum"],
  },
  {
    title: "Dex",
    iconActive: Chart,
    iconInactive: Chart,
    isActive: true,
    isCurrentPage: false,
    link: PAGE_LINKS.DEX,
    isSubMenu: false,
    chains: ["coreum"],
  },
];
