import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import { styled } from "@mui/material/styles";

import { useAppStore } from "@/stores";

import SelectSendAsset from "./SelectSendAsset";
import { Box } from "@mui/material";
import PriceUsd from "../dex/PriceUsd";

interface SendFieldProps {
  usdRate?: number | null;
}

const SendField: React.FC<SendFieldProps> = observer(({ usdRate }) => {
  const {
    dexStore: { tradeOrderAmount },
    swapStore: {
      sendAsset,
      receiveAsset,
      setSendAmount,
      sendAmount,
      priceRate,
    },
  } = useAppStore();

  const [value, setValue] = useState<number>(sendAmount || 0);
  const [valueInXrp, setValueInXrp] = useState<number>(sendAmount || 0);

  useEffect(() => {
    setValue(tradeOrderAmount! || 0);
    setSendAmount(Number(tradeOrderAmount));
  }, [tradeOrderAmount, setSendAmount]);

  useEffect(() => {
    if (!sendAsset) return;
    if (sendAsset?.currencyHex === "XRP") {
      setValueInXrp(value);
    } else {
      setValueInXrp(priceRate ? Number(value) * priceRate : 0);
    }
  }, [sendAsset, priceRate, sendAmount, value]);

  const onChangeInput = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const rgx = /^[0-9]*[.,]?[0-9]*$/;
    let _value = e.target.value;
    if (!rgx.test(_value)) return;
    _value = _value.replace(/,/g, ".");

    setValue(Number(_value) || 0);
    setSendAmount(Number(_value));
  };

  useEffect(() => {
    setValue(sendAmount! || 0);
    setSendAmount(Number(sendAmount));
  }, [sendAmount, setSendAmount]);

  // const onClickMax = () => {
  //   setValue(sendAssetBalance?.toFixed(6));
  //   setSendAmount(Number(sendAssetBalance));
  // };

  return (
    <>
      <Grid mb={1} sx={fieldSx}>
        <Box sx={row}>
          <Box sx={tokenSx}>
            <SelectSendAsset />
          </Box>

          <Box sx={inputSx}>
            <InputStyled
              id="swap-base-input"
              name="swap-base"
              placeholder="0"
              value={value}
              // fullWidth
              disableUnderline
              autoComplete="off"
              type="text"
              onClick={(e) => e.stopPropagation()}
              onChange={onChangeInput}
              disabled={!sendAsset || !receiveAsset}
              inputProps={{
                pattern: "^[0-9]*[.,]?[0-9]*$",
                minLength: "1",
                maxLength: "79",
                inputMode: "decimal",
                autoCorrect: "off",
                spellCheck: "false",
              }}
            />
          </Box>
        </Box>
        <Box sx={rowBottom}>
          <Box>
            <Typography
              sx={(theme) => ({
                fontSize: "10px",
                fontWeight: 400,
                lineHeight: "10px",
                color: theme.palette.text.lightGray,
              })}
            >
              Selling
            </Typography>
          </Box>
          <Box>
            <PriceUsd
              price={valueInXrp && usdRate ? Number(valueInXrp) * usdRate : 0}
            />
          </Box>
        </Box>
      </Grid>
    </>
  );
});

const row = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: "5px",
};

const rowBottom = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "10px",
};

const tokenSx = {
  // backgroundColor: "#ff0000",
  // width: "40px",
};

const inputSx = {
  marginRight: "-10px",
};

const InputStyled = styled(Input)(({ theme }) => ({
  textAlign: "right",
  fontWeight: 600,
  fontSize: 14,
  "&.active": {
    fontWeight: 600,
  },
  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "input[type=number]": {
    MozAppearance: "textfield",
  },
}));

const fieldSx = (theme: any) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "0px 10px 0px 10px",

  borderRadius: "6.25px",
  background: "none",
  border: "1px solid #3B474F",
  backgroundColor: "transparent",
  cursor: "pointer",
});

export default SendField;
