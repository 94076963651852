import { observer } from "mobx-react";

import { useAppStore } from "@/stores";
import { tabsModeEnum } from "@/stores/data/dexData";

import Trade from "./Trade";
import OrderBooks from "./OrderBooks";
import OrderLogs from "./OrderLogs";

interface CoinTabContentsProps {}

const CoinTabContents: React.FC<CoinTabContentsProps> = observer(() => {
  const {
    dexStore: { tabsMode },
  } = useAppStore();

  if (tabsMode === tabsModeEnum.book) return <OrderBooks />;

  if (tabsMode === tabsModeEnum.orders) return <OrderLogs />;

  return <Trade />;
});

export default CoinTabContents;
