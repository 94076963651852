import React from "react";
import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";

import { useAppStore } from "@/stores";
import close from "@/assets/images/closeIcon.svg";

import SignWallet from "./SignWallet";
import WaitWallet from "./WaitWallet";
import { Height } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { head } from "lodash";
import zIndex from "@mui/material/styles/zIndex";

interface WalletProps {}

const Wallet: React.FC<WalletProps> = observer(() => {
  const theme = useTheme();

  const {
    walletStore: { closeWalletRequest, isSigningWallet, isWaitingWallet },
  } = useAppStore();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <DialogStyled
      fullScreen={fullScreen}
      fullWidth
      // maxWidth="550px"
      scroll={"paper"}
      // @ts-ignore
      PaperComponent="div"
      open={isSigningWallet || isWaitingWallet}
      onClose={closeWalletRequest}
      className="modal-body"
      pt={-5}
    >
      <DialogTitle
        sx={{
          padding: 0,
          margin: { xs: "0px 0 8px 0", sm: "0px 0 8px 0" },
          color: "#ffffff",
        }}
      >
        <Typography
          color="text.modal"
          fontWeight="700px"
          fontSize="20px"
          lineHeight="20px"
          marginBottom={0}
          component="h2"
        >
          {isSigningWallet
            ? "Connect to Wallet"
            : isWaitingWallet
            ? "Sign Transaction"
            : ""}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={(theme) => ({
          maxHeight: "100%",
          minHeight: "30vh",
          margin: "0px -16px 0px -20px",
          // marginTop: "20px",
          color: "#ffffff",
          [theme.breakpoints.down("sm")]: {
            maxHeight: "100%",
          },
        })}
      >
        <Grid height="100%">
          {isSigningWallet && <SignWallet />}
          {isWaitingWallet && <WaitWallet />}
        </Grid>
      </DialogContent>

      <Box
        onClick={closeWalletRequest}
        sx={{
          position: "absolute",
          top: { xs: 16, sm: 16 },
          right: 16,
          color: "#ffffff",
          height: "28px",
          width: "28px",
          backgroundColor: "#000",
          borderRadius: "8px",
          border: "1px solid #25333C",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <img className="close-icon" src={close} alt="close" />
      </Box>
    </DialogStyled>
  );
});

const DialogStyled = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-root": {
    backgroundColor: "#161F25",
  },
  "& .MuiDialog-paper": {
    width: "550px",
    height: "550px",
    backgroundColor: "#161F25",
    borderRadius: "15px", // Default for non-phone sizes
    padding: "16px 16px",
    backgroundImage: "none",

    [theme.breakpoints.down("sm")]: {
      borderRadius: "5px", // Smaller radius for phones
      backgroundColor: "#161F25",
      height: "100dvh",
      zIndex: 10000,
    },
  },
  "& .MuiTypography-root": {
    color: theme.palette.text.modal,
  },
  "& .MuiDialogContent-root": {
    color: "#ffffff",
  },
  "& .MuiMenuItem-root": {
    "&:hover": {
      borderRadius: "24px",
      backgroundColor: "#161F25",
    },
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#161F25",
    color: theme.palette.text.modal,
  },
}));

export default Wallet;
