import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import { Box, Typography, useTheme, Theme, useMediaQuery } from "@mui/material";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

import { styled } from "@mui/material/styles";

import { useAppStore } from "@/stores";
import { formatNumber } from "@/helpers/formatter";
import { ICurrency, IOffer } from "@/types";

import OrderCancelConfirm from "./OrderCancelConfirm";

// Custom styled tooltip
const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#0A1013CC",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#0A1013CC",
    position: "relative",
    left: "-10px",
    backdropFilter: "blur(5px)",
    [`${theme?.breakpoints?.down("sm")}`]: {
      position: "relative",
      left: "10px",
      top: "16px",
    },
  },
}));

interface OrderBooksProps {
  typeTable: string;
  baseCoin: ICurrency;
  quoteCoin: ICurrency;
  offersTable: IOffer[];
}

const OrderBooksTable: React.FC<OrderBooksProps> = observer(
  ({ typeTable, baseCoin, quoteCoin, offersTable }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const {
      accountStore: { account },
    } = useAppStore();

    const tableRef = useRef<any>();
    const scrollTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);

    const [loadedOnce, setLoadedOnce] = useState(false);
    const [canceledOffer, setCancelOffer] = useState<IOffer>();
    const [accumulatedData, setAccumulatedData] = useState<{
      avgPrice: number;
      amountTokenQuote: number;
      amountTokenBase: number;
    }>();
    const [hoveredIndex, setHoveredIndex] = useState(
      typeTable === "sell" ? 100000 : -1
    ); // Track hovered row index
    const [isScrolling, setIsScrolling] = useState(false);

    const maxVolume = offersTable.reduce(
      (acc, curr) => (acc > curr.volume ? acc : curr.volume),
      0
    );

    // Function to accumulate data
    const accumulateData = (offers: IOffer[], index: number) => {
      const offersData =
        typeTable === "buy"
          ? offers.slice(0, index + 1)
          : offers.slice(0, offers.length - index); // Get the data up to the hovered index

      const amountTokenBase = offersData.reduce(
        (acc, curr) => acc + curr.volume,
        0
      );

      const amountTokenQuote = offersData.reduce(
        (acc, curr) => acc + curr.total,
        0
      );
      const avgPrice =
        typeTable === "buy"
          ? offersData.reduce((acc, curr) => acc + curr.price, 0) / (index + 1)
          : offersData.reduce((acc, curr) => acc + curr.price, 0) /
            (offers.length - index);

      setAccumulatedData({
        avgPrice,
        amountTokenQuote,
        amountTokenBase,
      });
    };

    // Function to handle mouse enter and leave
    const handleMouseEnter = (offers: IOffer[], index: number) => {
      if (typeTable === "buy") setHoveredIndex(index);
      else setHoveredIndex(index);
      accumulateData(offers, index);
    };

    const handleMouseLeave = (offers: IOffer[]) => {
      setHoveredIndex(typeTable === "sell" ? offers?.length + 1 : -1); // Reset on mouse leave
    };
    //----------------------------------------------

    const _onHandelClickOrder = (offer: IOffer) => {
      if (offer.owner !== account) return;
      setCancelOffer(offer);
    };

    // Scrolling table by default to the bottom
    useEffect(() => {
      if (typeTable === "sell" && offersTable.length > 0 && !loadedOnce) {
        setLoadedOnce(true);
        tableRef.current.scrollTop = tableRef.current.scrollHeight;

        setTimeout(() => {
          setIsScrolling(false);
        }, 0);
      }
    }, [offersTable, loadedOnce, typeTable, isScrolling]);

    // Flagging scrolling to show tooltip
    useEffect(() => {
      const handleScroll = () => {
        if (!isScrolling) setIsScrolling(true);

        clearTimeout(tableRef.current);

        scrollTimeoutRef.current = setTimeout(() => {
          setIsScrolling(false);
        }, 200);
      };

      const container = tableRef.current;
      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
        if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current);
      };
    }, [isScrolling]);

    return (
      <>
        <Box
          ref={tableRef}
          sx={{
            display: "Flex",
            flexDirection: "column",
            maxHeight: 185,
            width: "auto",
            overflowY: "scroll",
            overflowX: "hidden",
            marginBottom: "10px",
          }}
        >
          {(typeTable === "sell"
            ? offersTable?.slice().reverse()
            : offersTable
          ).map((row: any, index: number) => {
            const hovered =
              typeTable === "sell"
                ? index >= hoveredIndex
                : index <= hoveredIndex;
            const total = (row.volume * 100) / maxVolume;
            const isLastHovered = index === hoveredIndex;

            return (
              <TooltipStyled
                key={typeTable + index}
                onMouseEnter={() => handleMouseEnter(offersTable, index)}
                onMouseLeave={() => handleMouseLeave(offersTable)}
                title={
                  <Box>
                    <Box sx={tooltipBox}>
                      <Typography sx={tooltipTextBox}>Av. Price</Typography>
                      <Typography sx={tooltipNumberBox}>
                        <strong>
                          {formatNumber(
                            Number(accumulatedData?.avgPrice),
                            2,
                            6
                          )}
                        </strong>
                      </Typography>
                    </Box>
                    <Box sx={tooltipBox}>
                      <Typography sx={tooltipTextBox}>
                        {" "}
                        {`${baseCoin?.ticker} Amount`}
                      </Typography>
                      <Typography sx={tooltipNumberBox}>
                        <strong>
                          {formatNumber(
                            Number(accumulatedData?.amountTokenBase),
                            2,
                            2
                          )}
                        </strong>
                      </Typography>
                    </Box>
                    <Box sx={tooltipBox}>
                      <Typography sx={tooltipTextBox}>
                        {" "}
                        {`${quoteCoin?.ticker} Amount`}{" "}
                      </Typography>
                      <Typography sx={tooltipNumberBox}>
                        <strong>
                          {formatNumber(
                            Number(accumulatedData?.amountTokenQuote),
                            2,
                            2
                          )}
                        </strong>
                      </Typography>
                    </Box>
                  </Box>
                }
                placement={isSmallScreen ? "top" : "right"}
                arrow
                open={hoveredIndex === index && !isScrolling}
              >
                <Box
                  key={typeTable + index}
                  onMouseEnter={() => handleMouseEnter(offersTable, index)}
                  onMouseLeave={() => handleMouseLeave(offersTable)}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignContent: "center",
                    position: "relative",
                    backgroundColor: hovered ? "#1D282F" : "transparent",
                    borderTop:
                      isLastHovered && typeTable === "sell"
                        ? "1px solid #79858C"
                        : null,
                    borderBottom:
                      isLastHovered && typeTable === "buy"
                        ? "1px solid #79858C" // Hover condition for the bottom border
                        : "1px solid #25333C", // Default bottom border when not hovered
                    fontWeight: 400,
                    fontSize: "10px",
                    minHeight: "25px",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      right: "0",
                      height: "100%",
                      background:
                        typeTable === "sell" ? "#D24B6B66" : "#7DB58366",
                      width: `${total}%`,
                      // marginRight: "-20px",
                      // marginLeft: "-20px",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      zIndex: 5,
                      width: "30%",
                      alignContent: "center",
                      textAlign: "left",
                      paddingLeft: 3,
                      color:
                        typeTable === "sell"
                          ? theme.palette.text.negativeFigure
                          : theme.palette.text.positiveFigure,
                    }}
                  >
                    {formatNumber(row.price, 2, 6)}
                  </Box>
                  <Box
                    sx={{
                      zIndex: 5,
                      width: "40%",
                      alignContent: "center",
                    }}
                  >
                    {formatNumber(row.volume, 2, 2)}
                  </Box>
                  <Box
                    sx={{
                      marginRight: "20px",
                      zIndex: 5,
                      width: "30%",
                      textAlign: "right",
                      alignContent: "center",
                    }}
                  >
                    {formatNumber(row.total, 2, 2)}
                  </Box>
                </Box>
              </TooltipStyled>
            );
          })}
        </Box>

        {canceledOffer && (
          <OrderCancelConfirm
            offer={canceledOffer}
            cancelCallback={() => setCancelOffer(undefined)}
          />
        )}
      </>
    );
  }
);

// const titleSx = (theme: Theme) => ({
//   marginBottom: 2,
//   fontWeight: 700,
//   fontSize: "12px",
//   color: theme.palette.text.secondary,
// });

// const spreadBox = () => ({
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "center",
//   paddingTop: "10px",
//   paddingBottom: "10px",
//   fontSize: "10px",
//   background: "#25333C",
//   zIndex: 5,
// });

const tooltipBox = () => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "15px",
});

const tooltipTextBox = (theme: Theme) => ({
  fontWeight: 400,
  fontSize: "10px",
  color: theme.palette.text.grayBorder,
});

const tooltipNumberBox = (theme: Theme) => ({
  fontWeight: 400,
  fontSize: "10px",
  color: theme.palette.text.secondary,
});
export default OrderBooksTable;
