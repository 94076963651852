import { useEffect, useRef, useMemo } from "react";
import { observer } from "mobx-react";
import { createChart, CrosshairMode } from "lightweight-charts";
import { Box, useTheme } from "@mui/material";
import moment from "moment";

import { useAppStore } from "@/stores";

interface ChartGraphProps {}

const ChartGraph: React.FC<ChartGraphProps> = observer(() => {
  const theme = useTheme();
  const {
    dexStore: { exchanges, newExchange, isChartLoading },
  } = useAppStore();

  const chartRef = useRef<any>();
  const chartContainerRef = useRef<any>();

  const chartOptions = useMemo(
    () => ({
      height: 460,
      rightPriceScale: {
        scaleMargins: {
          top: 0.3,
          bottom: 0.25,
        },
        borderVisible: true,
      },
      layout: {
        backgroundColor: "transparent",
        textColor: "#b1b1b1",
      },
      grid: {
        vertLines: {
          color: "rgba(42, 46, 57, 0.9)",
        },
        horzLines: {
          color: "rgba(42, 46, 57, 0.9)",
        },
      },
      timeScale: {
        borderColor: "rgba(42,46,57,0)",
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
    }),
    []
  );

  const chart = useRef<any>();
  const candleSeries = useRef<any>();
  const volumeSeries = useRef<any>();

  useEffect(() => {
    chart.current = createChart(chartRef.current, chartOptions);
    candleSeries.current = chart.current.addCandlestickSeries({
      upColor: theme.palette.green.main,
      downColor: theme.palette.red.main,
      borderDownColor: theme.palette.red.main,
      borderUpColor: theme.palette.green.main,
      wickDownColor: theme.palette.red.main,
      wickUpColor: theme.palette.green.main,
    });
    candleSeries.current.applyOptions({
      priceFormat: {
        type: "price",
        precision: 6,
        minMove: 0.000001,
      },
      scaleMargins: {
        top: 0.1,
        bottom: 0.3,
      },
    });
    volumeSeries.current = chart.current.addHistogramSeries({
      color: "#a100b9",
      priceFormat: {
        type: "volume",
      },
      priceScaleId: "",
      scaleMargins: {
        top: 0.8,
        bottom: 0.03,
      },
    });

    if (
      chartRef.current &&
      candleSeries.current &&
      volumeSeries.current &&
      exchanges.length > 0
    ) {
      //chart.timeScale().fitContent();
      let prices = [];
      let volume = [];
      for (let i = 0; i < exchanges.length; i++) {
        const e = exchanges[i];
        const date = Date.parse(e.start) / 1000;

        if (e.high && e.open && e.close && e.vwap) {
          const high = parseFloat(e.high);
          if (high / parseFloat(e.vwap) > 5) {
            let newHigh = e.open;
            if (parseFloat(newHigh) < parseFloat(e.close)) newHigh = e.close;
            if (parseFloat(newHigh) < parseFloat(e.vwap)) newHigh = e.vwap;
            e.high = newHigh;
          }
        }

        prices.push({
          time: date,
          //value: parseFloat(e.close),
          open: parseFloat(e.open),
          close: parseFloat(e.close),
          high: parseFloat(e.high),
          low: parseFloat(e.low),
        });
        let color;
        if (parseFloat(e.close) > parseFloat(e.open)) {
          color = theme.palette.green.main; // green
        } else {
          color = theme.palette.red.main; // red
        }
        volume.push({
          time: date,
          value: parseFloat(e.base_volume),
          color,
        });
      }
      candleSeries.current.setData(prices);
      volumeSeries.current.setData(volume);
    }
    function handleResize() {
      const newWidth = chartContainerRef.current.offsetWidth;
      const newHeight = chartContainerRef.current.offsetHeight - 20;
      chart.current.applyOptions({ width: newWidth, height: newHeight });
    }
    window.addEventListener("resize", handleResize);

    return () => {
      chart.current.remove();
      window.removeEventListener("resize", handleResize);
    };
  }, [chartRef, chartContainerRef, chartOptions, exchanges, theme]);

  useEffect(() => {
    const updateChart = () => {
      if (
        !isChartLoading &&
        chart.current &&
        candleSeries.current &&
        volumeSeries.current &&
        newExchange
      ) {
        const e = newExchange;
        const date = Date.parse(e.start) / 1000;
        let color;
        if (parseFloat(e.close) > parseFloat(e.open)) {
          color = theme.palette.green.main; // green
        } else {
          color = theme.palette.red.main; // red
        }

        if (
          exchanges.length > 0 &&
          moment(e.close_time).isAfter(exchanges[0].close_time)
        ) {
          if (moment(e.start).isBefore(exchanges[0].start)) {
            return;
          }

          try {
            // compare new exchange with the last exchange
            const lastExchange = exchanges[exchanges.length - 1];
            // check if the new exchange is older than the last exchange
            if (moment(e.start).isBefore(lastExchange.start)) {
              return;
            }
            candleSeries.current.update({
              time: date,
              //value: parseFloat(e.close),
              open: parseFloat(e.open),
              close: parseFloat(e.close),
              high: parseFloat(e.high),
              low: parseFloat(e.low),
            });
          } catch (err) {
            console.log("Chart candle update error", err);
          }
          try {
            volumeSeries.current.update({
              time: date,
              value: parseFloat(e.base_volume),
              color,
            });
          } catch (err) {
            console.log("Chart volume update error", err);
          }
        }
      }
    };

    updateChart();
  }, [exchanges, newExchange, isChartLoading, theme]);

  return (
    <Box ref={chartContainerRef} sx={chartContainerSx}>
      <Box ref={chartRef} id="chart" />
    </Box>
  );
});

const chartContainerSx = {
  minHeight: "480px",
  maxHeight: "480px",
};

export default ChartGraph;
