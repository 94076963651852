import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Box, Typography, Theme } from "@mui/material";
import VerificationIcon from "../../../assets/images/icons/verification.svg";
import { formatNumber } from "@onxrp/ui-common";

interface CoinPairProps {
  quoteCoinTicker: string;
  baseCoinTicker: string;
  baseCoinIssuer: string;
  baseCoinName?: string;
  verified?: boolean;
  isMeme?: boolean;
  balance?: number;
  handleSelectChange: (
    baseCoinName: string,
    baseCoinIssuer: string,
    quoteCoinName: string
  ) => void;
}

const CoinPair: React.FC<CoinPairProps> = observer(
  ({
    quoteCoinTicker,
    baseCoinTicker,
    baseCoinIssuer,
    baseCoinName,
    verified,
    isMeme,
    balance = 0,
    handleSelectChange,
  }) => {
    return (
      <>
        <Link
          to={`/?base=${baseCoinTicker}&baseissuer=${baseCoinIssuer}&quote=${quoteCoinTicker}`}
          key={baseCoinTicker}
        >
          <Box
            sx={itemSx}
            onClick={() =>
              handleSelectChange(
                baseCoinTicker,
                baseCoinIssuer,
                quoteCoinTicker
              )
            }
          >
            <Box sx={pairSx}>
              <Typography sx={basecoindSx}>
                {baseCoinTicker}{" "}
                <Box component={"span"} sx={quiteCoinSx}>
                  / {quoteCoinTicker}
                </Box>
              </Typography>
              {/* TODO, add logic of verification in the future */}
              {baseCoinName && (
                <Typography sx={nameTokenSx}>({baseCoinName})</Typography>
              )}
              <Box
                component={"span"}
                sx={{ marginLeft: "8px", height: "16px" }}
              >
                {verified && <img src={VerificationIcon} alt="success" />}
              </Box>
              <Typography sx={basecoindSx}>
                {isMeme && <Typography>FL</Typography>}
              </Typography>
            </Box>

            <Box>
              {balance > 0 ? (
                <Typography>{`${formatNumber(
                  Number(balance),
                  2,
                  2
                )}`}</Typography>
              ) : null}
            </Box>
          </Box>
        </Link>
      </>
    );
  }
);

const itemSx = {
  padding: "0px 0px 0px 0px",
  margin: "5px 0px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    borderRadius: "4px !important",
    backgroundColor: "#1D282F !important",
    transition: "all 0.3s ease-in-out",
    "& svg": {
      opacity: 1,
    },
  },
};

const pairSx = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
};

const basecoindSx = (theme: Theme) => ({
  fontSize: "12px",
  fontWeight: 700,
  lineHeight: "12px",
  color: theme.palette.text.secondary,
});

const quiteCoinSx = (theme: Theme) => ({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "13px",
  color: theme.palette.text.grayBorder,
});

const nameTokenSx = (theme: Theme) => ({
  marginLeft: "10px",
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "10px",
  color: theme.palette.text.grayBorder,
});

export default CoinPair;
